import { Pipe, PipeTransform } from '@angular/core';
import { ProfessionType } from 'app/shared/models';

@Pipe({
  name: 'professionType',
})
export class ProfessionTypePipe implements PipeTransform {
  private readonly professionTypesList = {
    [ProfessionType.Doctor]: 'admin_area.user_profession_types.doctor',
    [ProfessionType.Advisor]: 'admin_area.user_profession_types.advisor',
    [ProfessionType.Other]: 'admin_area.user_profession_types.other',
  };

  transform(value: ProfessionType): string {
    const transformedValue = this.professionTypesList[value];
    if (transformedValue === undefined) {
      throw new Error(`Invalid Profession Type value: ${value}`);
    }
    return transformedValue;
  }
}
