import { createAction, props } from '@ngrx/store';

export namespace MainNavigationActions {
  export enum MainNavigationActionTypes {
    GoToProfile = '[Router] Navigate to profile page',
    GoToAdminArea = '[Router] Navigate to admin area page',
    GoToUserDetails = '[Router] Navigate to user details page',
    GoToLegalNotice = '[Router] Navigate to legal notice page',
    GoToPrivacyPolicy = '[Router] Navigate to privacy policy page',
  }
  export const goToProfile = createAction(MainNavigationActionTypes.GoToProfile);

  export const goToLegalNotice = createAction(MainNavigationActionTypes.GoToLegalNotice);

  export const goToPrivacyPolicy = createAction(MainNavigationActionTypes.GoToPrivacyPolicy);

  export const goToAdminArea = createAction(MainNavigationActionTypes.GoToAdminArea);

  export const goToUserDetails = createAction(
    MainNavigationActionTypes.GoToUserDetails,
    props<{ userId: string }>()
  );
}
