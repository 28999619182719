import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';
import { environment } from 'src/environments/environment';

import { AUTH_STATE_KEY } from './definitions/auth.definitions';
import { AuthPasswordEffects } from './effects/auth-password.effects';
import { AuthEffects } from './effects/auth.effects';
import { AdminGuard } from './guards/admin-guard.service';
import { LoggedInGuard } from './guards/logged-in-guard.service';
import { LoggedOutGuard } from './guards/logged-out-guard.service';
import { AuthTokenInvalidInterceptor } from './interceptors/auth-token-invalid.interceptor';
import { CustomHeaderInjectorInterceptor } from './interceptors/custom-header-injector.interceptor';
import { authReducer } from './reducers/auth.reducer';
import { AuthService } from './services/auth.service';
import { MockAuthService } from './services/mock-auth.service';
import { DashboardGuardService } from 'app/core/auth/guards/dashboard-guard.service';
import { AuthSignUpEffects } from 'app/core/auth/effects/auth-sign-up.effects';
import {
  LanguageInjectorInterceptor
} from 'app/core/auth/interceptors/language-injector.interceptor';

@NgModule({
  imports: [
    StoreModule.forFeature(AUTH_STATE_KEY, authReducer),
    EffectsModule.forFeature([AuthEffects, AuthPasswordEffects, AuthSignUpEffects]),
    CustomTranslatorModule,
  ],
  providers: [
    LoggedInGuard,
    LoggedOutGuard,
    AdminGuard,
    DashboardGuardService,
    {
      provide: AuthService,
      useClass: environment.useMockServices ? MockAuthService : AuthService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHeaderInjectorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInvalidInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInjectorInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
