import { Routes } from '@angular/router';

import { USER_UI_PATHS } from './core/users/definitions/user-ui-navigation.definitions';
import { DashboardGuardService } from 'app/core/auth/guards/dashboard-guard.service';
import { MAIN_NAVIGATION_PATHS } from './core/router/definitions/main-navigation.definitions';
import { PrivacyPolicyComponent } from './views/privacy-policy-ui/compopnents/privacy-policy.component';
import { LegalNoticeComponent } from './views/legal-notice-ui/components/legal-notice.component';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/auth-ui/auth-ui.module').then((m) => m.AuthUiModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./views/admin-ui/admin-ui.module').then((m) => m.AdminUiModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users-ui/users-ui.module').then((m) => m.UsersUiModule),
    canActivate: [DashboardGuardService],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./views/profile-ui/profile-ui.module').then((m) => m.ProfileUiModule),
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.legalNotice}`,
    component: LegalNoticeComponent,
  },
  {
    path: `${MAIN_NAVIGATION_PATHS.privacyPolicy}`,
    component: PrivacyPolicyComponent,
  },
  {
    path: '**',
    redirectTo: `${USER_UI_PATHS.root.users}`,
  },
];
