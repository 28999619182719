import { Pipe, PipeTransform } from '@angular/core';
import { User, UserRole } from '../models';

const userRoleValueMap: { [key in UserRole]: number } = {
  [UserRole.Admin]: 1,
  [UserRole.SuperAdvisor]: 2,
  [UserRole.Advisor]: 3,
  [UserRole.User]: 4,
  [UserRole.RelativeUserAccount]: 5
};

@Pipe({
  name: 'userSort',
})
export class SortUserPipe implements PipeTransform {
  transform(users: User[]): User[] {
    return users.sort((a, b) => {
      const roleComparison = userRoleValueMap[a.role] - userRoleValueMap[b.role];
      if (roleComparison !== 0) {
        return roleComparison;
      } else {
        return a.lastName.localeCompare(b.lastName);
      }
    });
  }
}
