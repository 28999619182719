import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { WeightHistoryData } from 'app/shared/models';
import { UserDataApiActions } from '../actions/user-data-api.actions';
import { v4 } from 'uuid';

export interface WeightHistoryState extends EntityState<WeightHistoryData> {}

export const weightHistoryAdapter = createEntityAdapter<WeightHistoryData>({
  selectId: () => v4(),
});

const initialState = weightHistoryAdapter.getInitialState();

export const weightHistoryReducer = createReducer(
  initialState,
  on(UserDataApiActions.getUserWeightHistoryDataSuccess, (state, { payload }) =>
    weightHistoryAdapter.upsertMany(payload, { ...state })
  ),
  on(UserDataApiActions.purgeUserData, (state) => weightHistoryAdapter.removeAll({ ...state }))
);
