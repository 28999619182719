import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SYMPTOMS_QUESTION_GROUPS
} from 'app/shared/components/charts/symptons-yes-no-bar-chart/definitions/symptoms-question-groups';

import { SELECTED_USER_DATA_STATE_KEY } from '../definitions/users.definitions';
import { UserDataRootState } from '../reducers';
import { generalHealthHistoryAdapter } from '../reducers/general-health-history.reducers';
import { weightHistoryAdapter } from '../reducers/weight-history.reducers';

export namespace UserDataSelectors {
  export const getUserDataFeatureState = createFeatureSelector<UserDataRootState>(
    SELECTED_USER_DATA_STATE_KEY
  );

  export const getSubmissionsState = createSelector(
    getUserDataFeatureState,
    (state) => state.submissionsState
  );

  export const getWeightHistoryState = createSelector(
    getUserDataFeatureState,
    (state) => state.weightHistory
  );

  export const getGeneralHealthHistoryState = createSelector(
    getUserDataFeatureState,
    (state) => state.generalHealthHistory
  );

  export const getSymptomsHistoryData = (questionGroup: SYMPTOMS_QUESTION_GROUPS) =>
    createSelector(getUserDataFeatureState, (state) => {
      return state.symptomsHistory.entities[questionGroup]?.symptomHistoryData
    });

  export const { selectAll: getWeightHistoryEntries, selectIds: getWeightHistoryIds } =
    weightHistoryAdapter.getSelectors(getWeightHistoryState);

  export const {
    selectAll: getGeneralHealthHistoryEntries,
    selectIds: getGeneralHealthHistoryIds,
  } = generalHealthHistoryAdapter.getSelectors(getGeneralHealthHistoryState);

  export const getSubmissions = createSelector(getSubmissionsState, (state) => state.submissions);


  export const getSubmissionDetails = createSelector(
    getSubmissionsState,
    (state) => state.submissionDetails
  );
}
