export const USER_UI_PATH_ELEMENTS = {
  rootElement: 'users',
};

export const USERS_PARAMS = {
  submissionId: 'submissionId',
};

export const USER_UI_PATHS = {
  root: {
    users: `/${USER_UI_PATH_ELEMENTS.rootElement}`,
  },
};
