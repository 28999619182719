<div class="">
  <div class="modal-content">
    <!-- head -->
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'modal.invite_monitored_user.title' | translate }}
      </h5>
      <button type="button" class="btn-secondary btn btn-close" (click)="close()">
        <span>&times;</span>
      </button>
    </div>

    <!-- main -->
    <div class="modal-body pt-0">
      <!-- Body -->
      <div body>
        <div class="mb-2">
          {{ 'modal.invite_monitored_user.message_question' | translate }}
        </div>

        <label for="emailAddress" class="form-label">{{
            'auth.forms.email_address' | translate
          }}</label>
        <input
          id="emailAddress"
          name="emailAddress"
          type="email"
          class="form-control form-control-lg"
          [formControl]="emailAddress"
          [class.is-invalid]="emailAddress.value && emailAddress.invalid"
        />
      </div>
    </div>

    <!-- footer -->
    <div class="modal-footer py-4 px-2">
      <div footer class="d-flex gap-2">
        <button class="btn btn-secondary" (click)="close()">
          {{ 'modal.default_cancel_button' | translate }}
        </button>
        <button class="btn btn-danger" (click)="handleSubmitInvitation()">
          {{ 'modal.invite_monitored_user.submit' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
