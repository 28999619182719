import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codeFormat',
})
export class CodeFormatPipe implements PipeTransform {
  transform(code: string | null): string {
    if (!code) {
      return '';
    }

    return code.replace(/(.{4})(.{4})(.{4})/, '$1-$2-$3');
  }
}
