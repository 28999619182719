import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './services/toast.service';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      toastComponent: ToastComponent,
    }),
  ],
  declarations: [ToastComponent],
})
export class ToastModule {
  static forRoot(): ModuleWithProviders<RootToastModule> {
    return {
      // eslint:disable-next-line:no-use-before-declare
      ngModule: RootToastModule,
      providers: [ToastService],
    };
  }
}

@NgModule({
  imports: [ToastModule],
})
export class RootToastModule {}
