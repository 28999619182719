import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MainNavigationActions } from 'app/core/router/actions/main-navigation.action';
import { BaseAppState } from 'app/core/store/reducers';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  constructor(private store: Store<BaseAppState>) {}

  handlePrivacyPolicyClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(MainNavigationActions.goToPrivacyPolicy());
  }

  handleLegalNoticeClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(MainNavigationActions.goToLegalNotice());
  }
}
