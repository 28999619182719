import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByProperty',
})
export class SortByPropertyPipe implements PipeTransform {
  transform<T extends object = object>(objectArray: T[], propertyName: keyof T) {
    return [...objectArray].sort((a, b) => a[propertyName as string] - b[propertyName as string]);
  }
}
