import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAVBAR_STATE_KEY } from '../definitions/nav-bar.definitions';
import { NavbarState } from '../reducers/nav-bar.reducers';

export namespace NavbarSelectors {
  const getNavbarState = createFeatureSelector<NavbarState>(NAVBAR_STATE_KEY);

  export const getIsHamburgerMenuOpen = createSelector(
    getNavbarState,
    (state: NavbarState) => state.isHamburgerMenuOpen
  );
}
