export const MAIN_NAVIGATION_PATH_ELEMENTS = {
  profile: 'profile',
  admin: 'admin',
  userDetails: 'user-details',
  legalNotice: 'legal-notice',
  privacyPolicy: 'privacy-policy',
};

export const MAIN_NAVIGATION_PATHS = {
  legalNotice: `${MAIN_NAVIGATION_PATH_ELEMENTS.legalNotice}`,
  privacyPolicy: `${MAIN_NAVIGATION_PATH_ELEMENTS.privacyPolicy}`,
  profile: `/${MAIN_NAVIGATION_PATH_ELEMENTS.profile}`,
  admin: `/${MAIN_NAVIGATION_PATH_ELEMENTS.admin}`,
  userDetails: `/${MAIN_NAVIGATION_PATH_ELEMENTS.admin}/${MAIN_NAVIGATION_PATH_ELEMENTS.userDetails}`,
};
