<ng-container *ngIf="!isAnalysisView">
  <ng-container *ngIf="hasSubmissions$ | async; else noSubmissions">
    <ng-container *ngIf="submissions$ | async as submissions">
      <ng-container *ngFor="let item of submissions | keyvalue">
        <h4 class="text-white">{{ item.key | date: 'MMMM YYYY' }}</h4>
        <div class="row d-flex flex-md-row submission-container">
          <ng-container *ngFor="let submission of item.value; let i = index">
            <div class="col-md-6" [ngClass]="{ 'order-md-1': i % 2 !== 0, 'order-md-2': i % 2 === 0 }">
              <div class="survey-list-item" (click)="onSurveyClick(submission)">
                <img *ngIf="submission.isCompleted" src="assets/images/success-icon.svg" />
                <img *ngIf="!submission.isCompleted" src="assets/images/warning-icon.svg" />
                <div class="survey-list-details">
                  <span class="text-muted">
                    <small>{{ submission.createdAt | date: 'dd.MM.YYYY HH:mm' }}</small>
                  </span>
                  <span>
                    <strong *ngIf="submission.isCompleted">
                      {{ 'monitored_user_submissions.complete' | translate }}
                      <ng-container *ngIf="submission.completedBy">
                        {{ 'monitored_user_submissions.completed_by' | translate: { name: submission.completedBy } }}
                      </ng-container>
                    </strong>
                    <strong *ngIf="!submission.isCompleted">
                      {{ 'monitored_user_submissions.incomplete' | translate }}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noSubmissions>
    <div class="col-md-12">
      <span class="text-white">
        {{ 'monitored_user_submissions.empty' | translate }}
      </span>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="isAnalysisView">
  <!-- Headline -->
  <div class="card p-4">
    <h2>{{ 'monitored_user_submissions.analysis' | translate }}</h2>
    <p>{{ 'monitored_user_submissions.subtitle' | translate }}</p>

    <!-- Table for Questions & Answers -->
    <table class="table">
      <tbody>
        <ng-container *ngIf="submissionDetails$ | async as submissionDetails">
          <ng-container *ngFor="let question of submissionDetails.questions">
            <tr>
              <td>{{ question.question }}</td>
              <td>
                <div class="value" [style.background-color]="question.answers[0] | colorPipe:question.type">
                  {{ question.answers[0] | answerDisplay:question.type | translate }}
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <button class="btn btn-primary mt-4" (click)="switchBackToListView()">{{ 'monitored_user_submissions.back' | translate }}</button>
  </div>
</ng-container>
