<div class="remove-modal-dialog">
  <div class="modal-content">
    <!-- head -->
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'modal.confirm_change_flow.title' | translate }}
      </h5>
      <button type="button" class="btn-secondary btn btn-close" (click)="handleCloseDialogClick()">
        <span>&times;</span>
      </button>
    </div>

    <!-- main -->
    <div class="modal-body pt-0">
      <!-- Body -->
      <div body>
        <div class="mb-2">
          {{ 'modal.confirm_change_flow.message_information' | translate }}
        </div>
        <div>
          {{ 'modal.confirm_change_flow.message_question' | translate }}
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="modal-footer py-4 px-2">
      <div footer class="d-flex gap-2">
        <button class="btn btn-secondary" (click)="close()">
          {{ 'modal.default_cancel_button' | translate }}
        </button>
        <button class="btn btn-danger" (click)="handleConfirmChangeFlow()">
          {{ 'modal.confirm_change_flow.button_confirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
