import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ModalsActions } from '../actions/modals.actions';
import { ModalsService } from '../services/modals.service';

@Injectable()
export class ModalsEffects {
  public openUserDetailsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openUserDetailsModal),
        tap((action) => {
          this.modalsService.openUserDetailsModal(action.userId);
        })
      ),
    { dispatch: false }
  );

  public closeUserDetailsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeUserDetailsModal),
        tap(() => this.modalsService.closeUserDetailsModal())
      ),
    { dispatch: false }
  );

  public openConfirmRemoveMonitoredUsersModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openConfirmRemoveMonitoredUsersModal),
        tap((action) => this.modalsService.openConfirmRemoveMonitoredUsersModal(action.userIds))
      ),
    { dispatch: false }
  );

  public openConfirmChangeFlowModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openConfirmChangeFlowModal),
        tap((action) => this.modalsService.openConfirmChangeFlowModal(action.request))
      ),
    { dispatch: false }
  );

  public openDisclaimerModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openDisclaimerModal),
        tap(() => this.modalsService.openDisclaimerModal())
      ),
    { dispatch: false }
  );

  public openConsentAgreementModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openConsentAgreementModal),
        tap(() => this.modalsService.openConsentAgreementModal())
      ),
    { dispatch: false }
  );

  public closeConfirmRemoveMonitoredUsersModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeConfirmRemoveMonitoredUsersModal),
        tap(() => this.modalsService.closeConfirmRemoveMonitoredUsersModal())
      ),
    { dispatch: false }
  );

  public closeConfirmChangeFlowModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeConfirmChangeFlowModal),
        tap(() => this.modalsService.closeConfirmChangeFlowModal())
      ),
    { dispatch: false }
  );

  public closeDisclaimerModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeDisclaimerModal),
        tap(() => this.modalsService.closeDisclaimerModal())
      ),
    { dispatch: false }
  );

  public closeConsentAgreementModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeConsentAgreementModal),
        tap(() => this.modalsService.closeConsentAgreementModal())
      ),
    { dispatch: false }
  );

  public openInviteMonitoredUserModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openInviteMonitoredUserModal),
        tap(() => this.modalsService.openInviteMonitoredUserModal())
      ),
    { dispatch: false }
  );

  public closeMonitoredUserInvitationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeInviteMonitoredUserModal),
        tap(() => this.modalsService.closeInviteMonitoredUserModal())
      ),
    { dispatch: false }
  );

  public openConfirmUserInvitationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.openConfirmUserInvitationModal),
        tap((data) => this.modalsService.openConfirmMonitoredUserInvitation(data))
      ),
    { dispatch: false }
  );

  public closeConfirmUserInvitationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalsActions.closeConfirmUserInvitationModal),
        tap((action) => this.modalsService.closeConfirmMonitoredUserInvitation(action.id))
      ),
    { dispatch: false }
  );

  constructor(private modalsService: ModalsService, private actions$: Actions) {}
}
