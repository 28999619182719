import { Component, Injector } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { BaseAppState } from 'app/core/store/reducers';
import { ObservableComponent } from 'app/shared/components/observable/observable.component';
import { fromInput } from 'app/shared/utilities/observable-utils';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class BaseModalComponent<T> extends ObservableComponent {
  public data?: T;
  public data$: Observable<T> = fromInput<BaseModalComponent<T>>(this)('data').pipe(
    filter((data) => !!data)
  ) as Observable<T>;
  public closeAction!: Action;

  protected store: Store<BaseAppState> = this.injector.get(Store) as Store<BaseAppState>;

  constructor(protected readonly injector: Injector) {
    super();
  }

  public close(): void {
    this.store.dispatch(this.closeAction);
  }
}
