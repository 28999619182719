import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthActions } from 'app/core/auth/actions/auth.actions';
import { AuthSelectors } from 'app/core/auth/selectors/auth.selectors';
import { NavbarActions } from 'app/core/nav-bar/actions/nav-bar.actions';
import { NavbarSelectors } from 'app/core/nav-bar/selectors/nav-bar.selectors';
import { MainNavigationActions } from 'app/core/router/actions/main-navigation.action';
import { BaseAppState } from 'app/core/store/reducers';
import { UserNavigationActions } from 'app/core/users/actions/user-ui-navigation.actions';

@Component({
  selector: 'app-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrls: ['./menu-overlay.component.scss'],
})
export class MenuOverlayComponent {
  public isMenuOpen$ = this.store.select(NavbarSelectors.getIsHamburgerMenuOpen);
  public isAdmin$ = this.store.pipe(select(AuthSelectors.getIsAdmin));

  constructor(private store: Store<BaseAppState>) {}

  public handleUsersOverviewClick(): void {
    this.store.dispatch(UserNavigationActions.goToUserList());
    this.toggleMenu();
  }

  public handleProfileClick(): void {
    this.store.dispatch(MainNavigationActions.goToProfile());
    this.toggleMenu();
  }

  public handleAdminAreaClick() {
    this.store.dispatch(MainNavigationActions.goToAdminArea());
    this.toggleMenu();
  }

  public handleLogoutClick(): void {
    this.store.dispatch(AuthActions.logout());
  }

  private toggleMenu() {
    this.store.dispatch(NavbarActions.toggleHamburgerMenu());
  }
}
