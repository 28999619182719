import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BaseAppState } from 'app/core/store/reducers';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthSelectors } from '../selectors/auth.selectors';
import { MAIN_NAVIGATION_PATHS } from 'app/core/router/definitions/main-navigation.definitions';

@Injectable()
export class DashboardGuardService implements CanActivate {
  isAdmin$ = this.store.pipe(select(AuthSelectors.getIsAdmin));

  constructor(private router: Router, private store: Store<BaseAppState>) {
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> {
    return this.isAdmin$.pipe(
      take(1),
      map((isAdmin) => {
        let redirectUrlTree: UrlTree;
        if (isAdmin) {
          redirectUrlTree = this.router.createUrlTree([MAIN_NAVIGATION_PATHS.admin]);
          return redirectUrlTree
        }
        return true;
      })
    );
  }
}
