export interface SubmissionListItem {
  id: string;
  createdAt: string;
  isCompleted: string;
  questions: SubmissionAnswer[];
  completedBy?: string;
}

export interface SubmissionAnswer {
  question: string;
  type: SurveyElementType;
  answers: number[];
}

export enum SurveyElementType {
  Info = 'info',
  SingleSelect = 'single-select',
  MultiSelect = 'multi-select',
  SingleSelectSlider = 'single-select-slider',
  Number = 'number',
}
