import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from 'app/core/store/reducers';

import { mergeMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthSelectors } from 'app/core/auth/selectors/auth.selectors';
import getUserLanguage = AuthSelectors.getUserLanguage;

const LANGUAGE_HEADER = 'x-custom-lang';

@Injectable()
export class LanguageInjectorInterceptor implements HttpInterceptor {
  token: string | undefined;

  constructor(private store: Store<BaseAppState>) {
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>
  > {
    return this.store.select(getUserLanguage).pipe(
      take(1),
      mergeMap((userLanguage) => {
        const newRequest = request.clone({
          setHeaders: {
            [LANGUAGE_HEADER]: userLanguage,
          },
        });

        return next.handle(newRequest);
      })
    );
  }
}
