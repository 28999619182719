import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from 'app/shared/models';

@Pipe({
  name: 'userRole',
})
export class RolesPipe implements PipeTransform {
  private readonly rolesList = {
    [UserRole.User]: 'admin_area.user_role.user',
    [UserRole.Admin]: 'admin_area.user_role.admin',
    [UserRole.SuperAdvisor]: 'admin_area.user_role.super_admin',
    [UserRole.Advisor]: 'admin_area.user_role.advisor',
  };

  transform(value: UserRole): string {
    const transformedValue = this.rolesList[value];
    if (transformedValue === undefined) {
      throw new Error(`Invalid User Role value: ${value}`);
    }
    return transformedValue;
  }
}
