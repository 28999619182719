<div class="">
  <div class="modal-content">
    <!-- head -->
    <div class="modal-header">
      <h5 class="modal-title">
        {{ 'modal.confirm_user_invitation.title' | translate }}
      </h5>
      <button type="button" class="btn-secondary btn btn-close" (click)="close()">
        <span>&times;</span>
      </button>
    </div>

    <!-- main -->
    <div class="modal-body pt-0">
      <!-- Body -->
      <div body>
        <div class="mb-2">
          {{ 'modal.confirm_user_invitation.message_question' | translate: { firstName: data?.firstName, lastName: data?.lastName} }}
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="modal-footer py-4 px-2">
      <div footer class="d-flex gap-2">
        <button class="btn btn-secondary" (click)="handleDeclineUserInvitation()">
          {{ 'modal.confirm_user_invitation.decline' | translate }}
        </button>
        <button class="btn btn-danger" (click)="handleConfirmUserInvitation()">
          {{ 'modal.confirm_user_invitation.confirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
