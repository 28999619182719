import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from 'app/shared/models';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  private readonly gendersList = {
    [Gender.Male]: 'admin_area.user_gender.male',
    [Gender.Female]: 'admin_area.user_gender.female',
    [Gender.Diverse]: 'admin_area.user_gender.diverse',
  };

  transform(value: Gender): string {
    const transformedValue = this.gendersList[value];
    if (transformedValue === undefined) {
      throw new Error(`Invalid Gender value: ${value}`);
    }
    return transformedValue;
  }
}
