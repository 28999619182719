import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { USER_UI_PATHS } from 'app/core/users/definitions/user-ui-navigation.definitions';
import { UserNavigationActions } from 'app/core/users/actions/user-ui-navigation.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserUINavigationEffects {
  goToUserList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserNavigationActions.goToUserList),
        tap(() => this.router.navigate([USER_UI_PATHS.root.users]))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {}
}
