import { createAction } from '@ngrx/store';

export enum AuthUINavigationActionTypes {
  GoToLogin = '[Router] Navigate to login',
  GoToRequestPasswordReset = '[Router] Navigate to request password reset',
  GoToTermsOfService = '[Router] Navigate to terms of service',
  GoToEditPassword = '[Router] Navigate to edit password',
  GoToSignUp = '[Router] Navigate to sign up',
  GoToSignUpSuccess = '[Router] Navigate to sign up success',
}

export const goToLogin = createAction(AuthUINavigationActionTypes.GoToLogin);
export const goToRequestPasswordReset = createAction(
  AuthUINavigationActionTypes.GoToRequestPasswordReset
);
export const goToTermsOfService = createAction(AuthUINavigationActionTypes.GoToTermsOfService);
export const goToEditPassword = createAction(AuthUINavigationActionTypes.GoToEditPassword);
export const goToSignUp = createAction(AuthUINavigationActionTypes.GoToSignUp);
export const goToSignUpSuccess = createAction(AuthUINavigationActionTypes.GoToSignUpSuccess);
