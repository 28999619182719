<div class="d-flex justify-content-center input-group mb-4">
  <input
    type="text"
    id="analysisDate"
    class="default-date-picker default-property-headline"
    bsDatepicker
    [placeholder]="analysisDate"
    [value]="analysisDate"
    [bsConfig]="datepickerConfig"
    (bsValueChange)="onDateChange($event)"
  />
</div>

<div class="chart-container">
  <div class="card chart-card mb-4">
    <!-- chart: health -->
    <div class="card-header">
      <div class="title">{{ 'general.charts.general_health.title' | translate }}</div>
    </div>
    <div class="chart">
      <app-general-health-chart
        [data]="generalHealthData$ | async"
        [date]="selectedDate"
      ></app-general-health-chart>
    </div>

    <!-- chart: symptoms -->
    <div class="card-header">
      <div class="title">{{ 'general.charts.symptoms.title' | translate }}</div>
    </div>
    <div class="chart">
      <app-symptoms-history-chart
        [data]="symptomsRedData$ | async"
        [date]="selectedDate"
      ></app-symptoms-history-chart>
    </div>

    <!-- chart: symptoms -->
    <div class="card-header">
      <div class="title">{{ 'general.charts.symptoms_yes_no.title' | translate }}</div>
    </div>
    <div class="chart mt-2">
      <app-symptons-yes-no-bar-chart
        [date]="selectedDate"
        [data]="(symptomsYellowData$ | async)!"
      ></app-symptons-yes-no-bar-chart>
    </div>

    <!-- chart: weight -->
    <div class="card-header">
      <div class="title">{{ 'general.charts.weight.title' | translate }}</div>
    </div>
    <div class="chart">
      <app-weight-chart [data]="weightData$ | async" [date]="selectedDate"></app-weight-chart>
    </div>
  </div>
</div>
