import { Injectable } from '@angular/core';
import {
  ApiResponse,
  GeneralHealthHistoryData,
  SymptomHistoryData,
  WeightHistoryData,
} from 'app/shared/models';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class UserDataMockApiService {
  public getUserWeightHistoryData(
    _userId: string,
    _monitoredUserId: string,
    _startDate?: Date,
    _endDate?: Date
  ): Observable<ApiResponse<WeightHistoryData[]>> {
    const res: ApiResponse<WeightHistoryData[]> = {
      data: [],
      success: true,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }

  public getGeneralHealthHistoryData(
    _userId: string,
    _monitoredUserId: string,
    _startDate?: Date,
    _endDate?: Date
  ): Observable<ApiResponse<GeneralHealthHistoryData[]>> {
    const res: ApiResponse<GeneralHealthHistoryData[]> = {
      data: [],
      success: true,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }

  public getSymptomsHistoryData(
    _userId: string,
    _monitoredUserId: string,
    _startDate?: Date,
    _endDate?: Date,
    _submissionId?: string
  ): Observable<ApiResponse<SymptomHistoryData[]>> {
    const res: ApiResponse<SymptomHistoryData[]> = {
      data: [],
      success: true,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }
}
