<div class="bg-light d-flex align-items-center">
  <div class="mt-2 mb-5">
    <div class="card-body">
      <div class="d-flex flex-row justify-content-between">
        <h2 class="card-title">{{ 'legal_pages.privacy_policy_title' | translate }}</h2>
        <button type="button" class="btn-secondary btn close" (click)="close()">
          <span>&times;</span>
        </button>
      </div>
      <div class="text-container mt-3" [innerHTML]="'legal_pages.privacy_policy' | translate"></div>
    </div>
  </div>
</div>
