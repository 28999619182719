import { Injectable } from '@angular/core';
import {
  ApiResponse,
  LoginResponse,
  LogoutRequest,
  ProfessionType,
  RenewCodeResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
  UserRole,
} from 'app/shared/models';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class MockAuthService {
  login(_email: string, _password: string): Observable<ApiResponse<LoginResponse>> {
    const res: ApiResponse<LoginResponse> = {
      success: true,
      validationErrors: [],
      data: {
        accessToken: 'defg',
        user: {
          email: 'test@abcd.de',
          firstName: 'Test',
          id: '1',
          lastName: 'User',
          createdAt: new Date(),
          role: UserRole.Advisor,
          allowedMonitoringUserIds: [''],
          scheduleIds: [''],
          monitoringMetadata: {
            professionType: ProfessionType.Advisor,
            street: 'Leyendecker Str. 3',
            postalCode: 50667,
            city: 'Köln',
            code: '01234',
            title: 'Arzt',
            officeName: 'Seracom',
            comment: '',
            confirmedTos: false,
          },
        },
      },
    };

    return of(res).pipe(delay(1000));
  }

  logout(_request: LogoutRequest) {
    const res: ApiResponse<{}> = {
      data: {},
      success: true,
      error: undefined,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }

  updatePassword(_request: UpdatePasswordRequest) {
    const res: ApiResponse<{}> = {
      success: true,
      validationErrors: [],
      data: {},
    };

    return of(res).pipe(delay(1000));
  }

  requestPasswordReset(_request: RequestPasswordResetRequest) {
    const res: ApiResponse<{}> = {
      success: true,
      validationErrors: [],
      data: {},
    };

    return of(res).pipe(delay(1000));
  }

  resetPassword(_request: ResetPasswordRequest) {
    const res: ApiResponse<{}> = {
      success: true,
      validationErrors: [],
      data: {},
    };

    return of(res).pipe(delay(1000));
  }

  renewCode(): Observable<ApiResponse<RenewCodeResponse>> {
    const res: ApiResponse<RenewCodeResponse> = {
      data: {
        code: '1234567899',
      },
      success: true,
      error: undefined,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }

  confirmTermsOfService(): Observable<ApiResponse<{}>> {
    const res: ApiResponse<{}> = {
      data: {},
      success: true,
      error: undefined,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }
}
