import { Action, createAction, props } from '@ngrx/store';
import { AsyncData } from '../models/async-action.model';

export enum AsyncStateActionTypes {
  SetAsyncStateStart = '[Async State Handler] Async Process Start',
  SetAsyncStateSuccess = '[Async State Handler] Async Process Success',
  SetAsyncStateFail = '[Async State Handler] Async Process Failure',
  SetAsyncStateReset = '[Async State Handler] Async Process Reset',
  SetAsyncStateRetry = '[Async State Handler] Async Process Retry',
}

export const setAsyncStateStart = createAction(
  AsyncStateActionTypes.SetAsyncStateStart,
  props<{ key: string; initialAction: Action }>()
);

export const setAsyncStateSuccess = createAction(
  AsyncStateActionTypes.SetAsyncStateSuccess,
  props<{ key: string }>()
);

export const setAsyncStateFail = createAction(
  AsyncStateActionTypes.SetAsyncStateFail,
  props<{ asyncErrorData: AsyncData }>()
);

export const setAsyncStateReset = createAction(
  AsyncStateActionTypes.SetAsyncStateReset,
  props<{ key: string }>()
);

export const setAsyncStateRetry = createAction(
  AsyncStateActionTypes.SetAsyncStateRetry,
  props<{ key: string; initialAction: Action }>()
);
