import { createReducer, on } from '@ngrx/store';
import { NavbarActions } from '../actions/nav-bar.actions';

export interface NavbarState {
  isHamburgerMenuOpen: boolean;
}

const initialState: NavbarState = {
  isHamburgerMenuOpen: false,
};

export const navbarReducer = createReducer(
  initialState,
  on(NavbarActions.toggleHamburgerMenu, (state) => ({
    ...state,
    isHamburgerMenuOpen: !state.isHamburgerMenuOpen,
  }))
);
