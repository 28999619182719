import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ModalsActions } from 'app/core/modals/actions/modals.actions';
import { UserListItem } from 'app/shared/models';
import { UserDetailsResponse } from 'app/shared/models/responses/get-user-details-response.module';

import { UserApiActions } from '../actions/user-api.actions';
import { updateUserSuccess } from '../actions/user-update-api.action';


export interface UsersState extends EntityState<UserListItem> {
  selectedUserId: string;
  userDetails: UserDetailsResponse | undefined;
}

export const usersAdapter = createEntityAdapter<UserListItem>({
  sortComparer: (a, b) => a.lastName.localeCompare(b.lastName),
});


const initialState: UsersState = usersAdapter.getInitialState({
  selectedUserId: '',
  userDetails: undefined,
});

export const usersReducer = createReducer(
  initialState,
  on(UserApiActions.listUsersSuccess, (state, { userListItem }) =>
    usersAdapter.upsertMany(userListItem, { ...state })
  ),
  on(UserApiActions.getMonitoredUsersSuccess, (state, { users }) =>
    usersAdapter.upsertMany(users, { ...state })
  ),
  on(ModalsActions.openUserDetailsModal, (state, { userId }) => ({
    ...state,
    selectedUserId: userId,
  })),
  on(UserApiActions.getUserDetailsSuccess, (state: UsersState, { userDetailsResponse }) => ({
    ...state,
    userDetails: userDetailsResponse,
  })),
  on(UserApiActions.removeMonitoredUsersSuccess, (state, { removedMonitoredUserIds }) => {
    return usersAdapter.removeMany(removedMonitoredUserIds, { ...state });
  }),
  on(updateUserSuccess, (state, { response }) => ({
    ...state,
    userResponse: response,
  })),
  on(UserApiActions.purgeUserDetails, (state) => ({
    ...state,
    userDetails: undefined,
  })),
  on(UserApiActions.confirmUserAccountSuccess, (state, { confirmed }) => ({
    ...state,
    userDetails: {
      ...state.userDetails,
      user: {
        ...state.userDetails!.user,
        isConfirmed: confirmed
      }
    }
  }))
);
