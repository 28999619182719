<div class="d-flex align-items-center h-100">
  <div class="container-md mt-2 mb-5">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">{{ 'legal_pages.privacy_policy_title' | translate }}</h2>

        <div
          class="your-legal-page-class"
          [innerHTML]="'legal_pages.privacy_policy' | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
