import * as generalHealthHistory from './general-health-history.reducers';
import * as symptomsHistory from './symptoms-history.reducers';
import * as submissions from './user-submissions.reducers';
import * as weightHistory from './weight-history.reducers';

export interface UserDataRootState {
  generalHealthHistory: generalHealthHistory.GeneralHealthHistoryState;
  symptomsHistory: symptomsHistory.SymptomsHistoryState;
  weightHistory: weightHistory.WeightHistoryState;
  submissionsState: submissions.UserSubmissionState;
}

export const userDataReducers = {
  generalHealthHistory: generalHealthHistory.generalHealthHistoryReducer,
  symptomsHistory: symptomsHistory.symptomHistoryReducer,
  weightHistory: weightHistory.weightHistoryReducer,
  submissionsState: submissions.userSubmissionsReducer,
};
