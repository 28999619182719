import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ColumnMode, DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { TableColumnProp } from '@swimlane/ngx-datatable/lib/types/table-column.type';

import {
  CustomTableColumnConfiguration,
  CustomTableConfiguration,
} from './custom-table-configuration.model';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableComponent<T> implements AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('datatable', { static: true }) public datatable: any;

  ngxConfig: Partial<DatatableComponent> = {};
  ngxDatatableColumns: (TableColumn & {
    fixedWidth?: boolean;
    flexGrow?: number;
    transformFn: CustomTableColumnConfiguration<T>['transformFn'];
  })[] = [];

  _config: CustomTableConfiguration<T> = {};
  @Input()
  set config(value: CustomTableConfiguration<T>) {
    if (!value.columnMode) {
      value.columnMode = ColumnMode.force;
    }

    this.ngxDatatableColumns =
      value?.columns?.map((column) => ({
        name: column.label || '',
        prop: (column.field || '') as TableColumnProp,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
        flexGrow: column.flexGrow,
        fixedWidth: column.fixedWidth,
        cellClass: column.cellClass,
        headerClass: column.headerClass,
        cellTemplate: column.cellTemplate,
        transformFn: column.transformFn,
        textBreak: column.textBreak ?? 'ellipsis',
      })) || [];

    this.ngxConfig = {
      headerHeight: value.hideHeader ? 0 : 50,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rowClass: (row: any) => {
        let rowClass = value.rowClass ?? '';

        if (!value.rowsSelectable) {
          rowClass += ' active-selection-none';
        }

        for (const key in value.propertyConditionalRowClassDict) {
          if (row[key]) {
            rowClass += ` ${value.propertyConditionalRowClassDict[key]}`;
          }
        }

        return rowClass;
      },
    };

    this._config = value;
  }
  get config(): CustomTableConfiguration<T> {
    return this._config;
  }

  @Input() data: T[];

  @Output() rowClicked = new EventEmitter<T>();

  ngAfterViewInit(): void {
    if (this._config.groupRowsBy) {
      this.datatable.groupRowsBy = this._config.groupRowsBy;
      this.datatable.groupExpansionDefault = true;
    }
  }

  public handleRowSelect({ selected }: { selected: T[] }) {
    this.rowClicked.emit(selected[0]);
  }

  public handleToggleGroupExpansion(group: { key: unknown; value: Array<T> }) {
    // this is necessary, otherwise the datatable library will auto expand all row groups as soon as the last
    // group is collapsed (it has to be true after view init so that the groups arent automatically collapsed)
    this.datatable.groupExpansionDefault = false;

    if (this.config.groupsExpandable) {
      this.datatable.groupHeader.toggleExpandGroup(group);
    }
  }
}
