import { props } from '@ngrx/store';
import { createAsyncAction } from 'app/core/async-state/utils/async-state-action.utils';
import {
  ApiResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
} from 'app/shared/models';

export enum PasswordActionTypes {
  EditPassword = '[Password] Edit password',
  EditPasswordSuccess = '[Password] Edit password success',
  EditPasswordFail = '[Password] Edit password failure',
  RequestPasswordReset = '[Password] Request password reset',
  RequestPasswordResetSuccess = '[Password] Request password reset success',
  RequestPasswordResetFail = '[Password] Request password reset failure',
  ResetPassword = '[Password] Reset password',
  ResetPasswordSuccess = '[Password] Reset password success',
  ResetPasswordFail = '[Password] Reset password failure',
}

export const editPassword = createAsyncAction(
  PasswordActionTypes.EditPassword,
  {
    asyncKey: PasswordActionTypes.EditPassword,
    asyncState: 'start',
  },
  props<{ payload: UpdatePasswordRequest }>()
);

export const editPasswordSuccess = createAsyncAction(
  PasswordActionTypes.EditPasswordSuccess,
  {
    asyncKey: PasswordActionTypes.EditPassword,
    asyncState: 'success',
  },
  props<{ response: ApiResponse<string> }>()
);

export const editPasswordFail = createAsyncAction(PasswordActionTypes.EditPasswordFail, {
  asyncKey: PasswordActionTypes.EditPassword,
  asyncState: 'fail',
});

export const requestPasswordReset = createAsyncAction(
  PasswordActionTypes.RequestPasswordReset,
  {
    asyncKey: PasswordActionTypes.RequestPasswordReset,
    asyncState: 'start',
  },
  props<{ payload: RequestPasswordResetRequest }>()
);

export const requestPasswordResetSuccess = createAsyncAction(
  PasswordActionTypes.RequestPasswordResetSuccess,
  {
    asyncKey: PasswordActionTypes.RequestPasswordReset,
    asyncState: 'success',
  },
  props<{ response: ApiResponse<string> }>()
);

export const requestPasswordResetFail = createAsyncAction(
  PasswordActionTypes.RequestPasswordResetFail,
  {
    asyncKey: PasswordActionTypes.RequestPasswordReset,
    asyncState: 'fail',
  }
);

export const resetPassword = createAsyncAction(
  PasswordActionTypes.ResetPassword,
  {
    asyncKey: PasswordActionTypes.ResetPassword,
    asyncState: 'start',
  },
  props<{ payload: ResetPasswordRequest }>()
);

export const resetPasswordSuccess = createAsyncAction(
  PasswordActionTypes.ResetPasswordSuccess,
  {
    asyncKey: PasswordActionTypes.ResetPassword,
    asyncState: 'success',
  },
  props<{ response: ApiResponse<string> }>()
);

export const resetPasswordFail = createAsyncAction(PasswordActionTypes.ResetPasswordFail, {
  asyncKey: PasswordActionTypes.ResetPassword,
  asyncState: 'fail',
});
