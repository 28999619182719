export enum CancerType {
  Unknown = -1,
  Other,
  Blood,
  Lymph,
  Stomach,
  Esphagus,
  Liver,
  Pancreas,
  BileDucts,
  Bowel,
  Lung,
  Breast,
  Uterus,
  Ovaries,
  Kidney,
  Bladder,
  Prostate,
}