<div class="btn-group" dropdown>
  <button
    dropdownToggle
    type="button"
    [ngClass]="{ open: isDropdownOpen }"
    class="btn dropdown-toggle"
    aria-controls="dropdown-basic"
  >
    <div [inlineSVG]="'assets/images/globe.svg'" class="svg-icon"></div>
    <p class="text">
      {{ 'general.' + (userLanguage$ | async) + '_language' | translate }}
    </p>
  </button>
  <ul
    id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="selectLanguage(language)"
        *ngFor="let language of translateService.langs"
      >
        {{ 'general.' + language + '_language' | translate }}
      </a>
    </li>
  </ul>
</div>
