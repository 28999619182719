import 'chartjs-adapter-moment';

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { fromInput } from 'app/shared/utilities/observable-utils';
import { Chart, ChartConfiguration } from 'chart.js';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ObservableComponent } from '../../observable/observable.component';
import { TranslateService } from '@ngx-translate/core';

export interface LineChartData {
  values: { x: number; y: number }[];
  color: string;
  label?: string;
}

@Component({
  selector: 'app-monthly-multiline-chart',
  templateUrl: './monthly-multiline-chart.component.html',
  styleUrls: ['./monthly-multiline-chart.component.scss'],
})
export class MonthlyMultilineChartComponent extends ObservableComponent {
  private readonly defaultPointRadius = 3;

  @Input() public subtitle: string = 'Subtitle';
  @Input() public aspectRatio: number = 2;
  @Input() public data?: LineChartData[] | null;
  @Input() public date?: Date | null = null;
  @Input() public translateLabels = false;
  @Input() public tension = 0;
  @Input() public unit?: string | null = null;
  @Input() public chartHeight?;
  @Input() public rangeYAxis?: { min: number; max: number } | null = null;

  public data$ = fromInput<MonthlyMultilineChartComponent>(this)('data');
  public date$ = fromInput<MonthlyMultilineChartComponent>(this)('date');
  public unit$ = fromInput<MonthlyMultilineChartComponent>(this)('unit');
  public rangeYAxis$ = fromInput<MonthlyMultilineChartComponent>(this)('rangeYAxis');

  public lineChartData?: ChartConfiguration['data']['datasets'];
  public lineChartLabels?: ChartConfiguration['data']['labels'];
  public lineChartOptions?: ChartConfiguration['options'];
  public lineChartPlugins: NonNullable<ChartConfiguration['plugins']> = [];
  public lineChartLegend = false;

  public activeIndices = new Set<number>();

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.setupChartOptions();
    this.observeChartData();
  }

  public handleLegendLabelClick(index: number): void {
    if (this.activeIndices.has(index)) {
      this.activeIndices.delete(index);
    } else {
      this.activeIndices.add(index);
    }

    if (this.activeIndices.size === 0 || this.activeIndices.size === this.data?.length) {
      this.activeIndices.clear();
      this.lineChartData = this.lineChartData!.map((data) => ({
        ...data,
        hidden: false,
      }));
      return;
    }

    this.lineChartData = this.lineChartData!.map((data, i) => ({
      ...data,
      hidden: !this.activeIndices.has(i),
    }));
    this.changeDetector.detectChanges();
  }
  private observeChartData(): void {
    this.data$
      .pipe(
        map((data) => data ?? []),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((data) => {
        this.lineChartData = [];
        for (const d of data) {
          this.lineChartData.push({
            data: d.values,
            borderColor: d.color,
            pointBorderColor: d.color,
            pointBackgroundColor: d.color,
            pointHoverBorderColor: d.color,
            pointRadius: this.defaultPointRadius,
            label: d.label ? this.translateService.instant(d.label) : '',
          });
        }
        this.changeDetector.detectChanges();
      });
  }

  private setupChartOptions(): void {
    Chart.defaults.font.size = 12;
    Chart.defaults.font.weight = 'bold';

    this.lineChartOptions = {
      responsive: true,
      animation: false,
      datasets: {
        line: {
          tension: this.tension,
          borderWidth: 1,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'DD',
            },
          },
          ticks: {
            color: '#A2A9BB',
          },
        },
        y: {
          position: 'right',
          grid: {
            drawBorder: false,
          },
          ticks: {
            padding: 8,
            color: '#A2A9BB',
            stepSize: 1,
          },
        },
      },
      aspectRatio: !this.chartHeight ? this.aspectRatio : undefined,
      maintainAspectRatio: !this.chartHeight,
    };
    this.changeDetector.detectChanges();

    combineLatest([this.date$, this.rangeYAxis$, this.unit$])
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(([date, rangeYAxis, unit]) => {
        this.lineChartOptions = {
          ...this.lineChartOptions,
          scales: {
            ...this.lineChartOptions?.scales,
            x: {
              ...this.lineChartOptions?.scales?.x,
              min: moment(date).startOf('month').valueOf(),
              max: moment(date).endOf('month').valueOf(),
            },
            y: {
              ...this.lineChartOptions?.scales?.y,
              min: rangeYAxis?.min,
              max: rangeYAxis?.max,
              ticks: {
                ...this.lineChartOptions?.scales?.y?.ticks,
                callback: (value) => `${value} ${unit ?? ''}`,
              },
            },
          },
        };
        this.changeDetector.detectChanges();
      });
  }
}
