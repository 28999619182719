<app-monthly-multiline-chart
  [chartHeight]="350"
  [rangeYAxis]="rangeYAxis"
  [data]="chartData$ | async"
  [date]="date"
  [title]="'general.charts.weight.title' | translate"
  [subtitle]="
    'general.charts.weight.subtitle'
      | translate: { month: date | date: 'MMMM', year: date | date: 'YYYY' }
  "
  [unit]="'general.charts.weight.unit' | translate"
  [tension]="0.2"
>
</app-monthly-multiline-chart>
