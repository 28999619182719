import { createReducer, on } from "@ngrx/store";
import { SubmissionsMap } from "app/shared/models/responses/get-submissions-response.modal";
import { SubmissionListItem } from 'app/shared/models/submissions/submission-list-item.model';
import { SubmissionApiActions } from "../actions/user-submissions-api.actions";

export interface UserSubmissionState {
  submissions: SubmissionsMap; 
  submissionDetails: SubmissionListItem | null;
}

const initialState: UserSubmissionState = {
  submissions: {},
  submissionDetails: null,
};

export const userSubmissionsReducer = createReducer(
  initialState,
  on(SubmissionApiActions.getSubmissionsSuccess, (state, { payload }) => ({
    ...state,
    submissions: payload.submissions,
  })),

  on(SubmissionApiActions.getSubmissionDetailsSuccess, (state, { payload }) => ({
    ...state,
    submissionDetails: payload,
  })),
  
  on(SubmissionApiActions.clearSubmissionDetails, (state) => ({
    ...state,
    submissionDetails: null,
  })),
);
