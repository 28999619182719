import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { ROUTER_STATE_KEY } from './definitions/router.definitions';
import { AuthUINavigationEffects } from './effects/auth-ui-navigation.effects';
import { MainNavigationEffects } from './effects/main-navigation.effect';
import { RouterEffects } from './effects/router.effects';
import { UserUINavigationEffects } from './effects/user-ui-navigation.effects';
import { CustomRouterStateSerializer } from './reducers/custom-router-state-serializer';

@NgModule({
  imports: [
    StoreModule.forFeature(ROUTER_STATE_KEY, routerReducer),
    EffectsModule.forFeature([
      RouterEffects,
      AuthUINavigationEffects,
      UserUINavigationEffects,
      MainNavigationEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: ROUTER_STATE_KEY,
    }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class RouterStateModule {}
