import { Pipe, PipeTransform } from '@angular/core';
import { CancerType } from 'app/shared/models';

@Pipe({
  name: 'cancerType',
})
export class CancerTypePipe implements PipeTransform {
  private readonly cancerTypesList = {
    [CancerType.Unknown]: 'general.cancer_type.unknown',
    [CancerType.Other]: 'general.cancer_type.other',
    [CancerType.Blood]: 'general.cancer_type.blood',
    [CancerType.Lymph]: 'general.cancer_type.lymph',
    [CancerType.Stomach]: 'general.cancer_type.stomach',
    [CancerType.Esphagus]: 'general.cancer_type.esphagus',
    [CancerType.Liver]: 'general.cancer_type.liver',
    [CancerType.Pancreas]: 'general.cancer_type.pancreas',
    [CancerType.BileDucts]: 'general.cancer_type.bileDucts',
    [CancerType.Bowel]: 'general.cancer_type.bowel',
    [CancerType.Lung]: 'general.cancer_type.lung',
    [CancerType.Breast]: 'general.cancer_type.breast',
    [CancerType.Uterus]: 'general.cancer_type.uterus',
    [CancerType.Ovaries]: 'general.cancer_type.ovaries',
    [CancerType.Kidney]: 'general.cancer_type.kidney',
    [CancerType.Bladder]: 'general.cancer_type.bladder',
    [CancerType.Prostate]: 'general.cancer_type.prostate',
  };

  transform(value: CancerType): string {
    const transformedValue = this.cancerTypesList[value];
    if (transformedValue === undefined) {
      throw new Error(`Invalid Cancer Type value: ${value}`);
    }
    return transformedValue;
  }
}
