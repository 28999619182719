import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule, TranslateService,
} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { CustomMissingTranslationHandler } from './missing-translation-handler';
import { createTranslateLoader } from './translator-loader-factory';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'app/core/auth/selectors/auth.selectors';
import getUserLanguage = AuthSelectors.getUserLanguage;

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      defaultLanguage: environment.defaultLanguage,
    }),
  ],
  exports: [TranslateModule],
})
export class CustomTranslatorModule {
  constructor(private translateService: TranslateService, private store: Store) {
    this.translateService.addLangs(['de', 'en']);
    this.onLanguageChange();
  }

  private onLanguageChange() {
    this.store.select(getUserLanguage).subscribe((language) => {
      this.translateService.use(language || 'de');
    });
  }
}
