<div class="subtitle pt-2 px-2">{{ subtitle }}</div>
<div class="chart-wrapper p-2"
     [ngStyle]="{ height: chartHeight ? chartHeight + 'px' : ''}">
  <canvas
    baseChart
    type="line"
    [class.empty]="!data?.length"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [legend]="lineChartLegend"
    [plugins]="lineChartPlugins"
  ></canvas>
  <div class="empty" *ngIf="!data?.length">
    {{ 'general.charts.empty_message' | translate }}
  </div>
</div>
<div class="legend row p-3" *ngIf="(data?.length || 0) > 1">
  <div
    *ngFor="let item of data; let i = index"
    class="item col-sm-4 col-6 my-1"
    [class.active]="activeIndices.has(i) || activeIndices.size === 0"
    (click)="handleLegendLabelClick(i)"
  >
    <div class="indicator" [style.backgroundColor]="item.color"></div>
    <div class="label" *ngIf="item.label">
      <ng-container *ngIf="translateLabels">{{ item.label | translate }}</ng-container>
      <ng-container *ngIf="!translateLabels">{{ item.label }}</ng-container>
    </div>
  </div>
</div>
