import { Pipe, PipeTransform } from '@angular/core';
import { SurveyElementType } from '../models/submissions/submission-list-item.model';

@Pipe({
  name: 'colorPipe',
})

export class ColorPipe implements PipeTransform {
  private positiveColor = { r: 48, g: 188, b: 237, a: 1 };
  private negativeColor = { r: 52, g: 220, b: 119, a: 0.5 };

  transform(value: number, type: SurveyElementType): string {
    let color: string;
  
    if (type === SurveyElementType.SingleSelect) {
      switch (value) {
        case 0:
          color = this.getColorString(this.negativeColor);
          break;
        case 2:
          color = this.getColorString(this.positiveColor);
          break;
        default:
          color = this.getColorString(this.positiveColor);
          break;
      }
    } else if (type === SurveyElementType.SingleSelectSlider || type === SurveyElementType.Number) {
      const ratio = Math.min(Math.max(value, 0), 10) / 10;
      const interpolatedColor = this.interpolateColor(
        this.negativeColor,
        this.positiveColor,
        ratio
      );
      color = this.getColorString(interpolatedColor);
    } else {
      color = this.getColorString(this.positiveColor);
    }
  
    return color;
  }

  private interpolateColor(start: any, end: any, ratio: number): any {
    const r = Math.round(start.r + (end.r - start.r) * ratio);
    const g = Math.round(start.g + (end.g - start.g) * ratio);
    const b = Math.round(start.b + (end.b - start.b) * ratio);
    const a = start.a + (end.a - start.a) * ratio;
    return { r, g, b, a };
  }

  private getColorString(color: any): string {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }
}
