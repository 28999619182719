<nav class="navbar navbar-dark">
  <div class="container-fluid">
    <a class="navbar-brand cursor-pointer" href="#">
      <div class="flex-1 flex-row" *ngIf="hideLogo$ | async">
        <div id="back-button" class="d-flex">
          <div [inlineSVG]="'assets/images/arrow-left.svg'"></div>
          <span>{{ 'general.back' | translate }}</span>
        </div>
      </div>

      <ng-container *ngIf="(hideLogo$ | async) === false">
        <img src="assets/images/Esprio.svg" alt="{{ 'general.title' | translate }}" />
      </ng-container>
    </a>

    <div class="d-flex flex-1 flex-justify-end flex-align-center gap-1">
      <div class="me-1">
        <app-language-picker></app-language-picker>
      </div>

      <ng-container *ngIf="isLoggedIn$ | async">
        <div
          id="hamburger-menu"
          [class.open]="isMenuOpen$ | async"
          (click)="handleToggleHamburgerMenu()"
        >
          <span></span>
        </div>
      </ng-container>
    </div>
  </div>
</nav>
