export const AUTH_UI_PATH_ELEMENTS = {
  rootElement: 'auth',
  root: {
    loginElement: 'login',
    signUpElement: 'sign-up',
    signUpSuccessElement: 'sign-up-success',
    signUpConfirmElement: 'sign-up-confirm',
    passwordResetElement: 'password-reset',
    passwordReset: {
      requestElement: 'request',
      resetElement: 'reset',
    },
    termsOfServiceElement: 'terms-of-service',
    editPasswordElement: 'edit-password',
  },
};

export const AUTH_UI_PATHS = {
  root: {
    login: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.loginElement}`,
    signUp: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpElement}`,
    signUpSuccess: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpSuccessElement}`,
    signUpConfirm: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.signUpConfirmElement}`,
    passwordReset: {
      request: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.requestElement}`,
      reset: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.resetElement}`,
    },
    termsOfService: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.termsOfServiceElement}`,
    editPassword: `/${AUTH_UI_PATH_ELEMENTS.rootElement}/${AUTH_UI_PATH_ELEMENTS.root.editPasswordElement}`,
  },
};
