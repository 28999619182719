<app-monthly-multiline-chart
  [chartHeight]="350"
  [rangeYAxis]="{ min: 0, max: 10 }"
  [data]="chartData$ | async"
  [date]="date"
  [title]="'general.charts.general_health.title' | translate"
  [subtitle]="
    'general.charts.general_health.subtitle'
      | translate: { month: date | date: 'MMMM', year: date | date: 'YYYY' }
  "
  [translateLabels]="true"
>
</app-monthly-multiline-chart>
