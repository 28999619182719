import { CancerTherapyType } from './cancer-therapy-type.model';
import { CancerType } from './cancer-type.model';
import { Gender } from './gender.model';

export class UserCoreData {
  gender: Gender;
  birthday: string;
  bodySizeInCm: number;
  initialWeightInKg: number;
  weightSixMonthsAgoInKg: number;
  generalWellBeing: number;
  cancerTypes: CancerType[];
  therapyTypes: CancerTherapyType[];
}
