import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { API_BASE_URL } from './app.definitions';
import { appRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { CustomTranslatorModule } from './shared/i18n/custom-translate.module';
import { SharedModule } from './shared/shared.module';
import { NavBarUiModule } from './views/nav-bar-ui/nav-bar-ui.module';
import { ViewsModule } from './views/views.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
    CoreModule,
    NavBarUiModule,
    CustomTranslatorModule,
    InlineSVGModule,
    SharedModule,
    ViewsModule,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
