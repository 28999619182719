import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from 'app/app.definitions';
import { ApiResponse } from 'app/shared/models';
import { GetSubmissionsResponse } from 'app/shared/models/responses/get-submissions-response.modal';
import { SubmissionListItem } from 'app/shared/models/submissions/submission-list-item.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserSubmissionsApiService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private httpClient: HttpClient) {}

  public getSubmissions(userId: string, monitoredUserId: string): Observable<ApiResponse<GetSubmissionsResponse>> {
    return this.httpClient.get<ApiResponse<GetSubmissionsResponse>>(
      `${this.apiBaseUrl}/monitoring/${userId}/${monitoredUserId}/surveys`
    );
  }

  public getSubmissionDetails(userId: string, monitoredUserId: string, submissionId: string): Observable<ApiResponse<SubmissionListItem>> {
    return this.httpClient.get<ApiResponse<SubmissionListItem>>(
      `${this.apiBaseUrl}/users/${monitoredUserId}/submissions/${submissionId}/requested-by/${userId}`
    );
  }
}
