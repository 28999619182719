import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from 'app/core/async-state/utils/async-state-action.utils';
import { UserListItem } from 'app/shared/models';
import { UserDetailsResponse } from 'app/shared/models/responses/get-user-details-response.module';
import { ChangeMonitoredUserFlowRequest } from 'app/shared/models/users/requests/change-monitored-user-flow-request.model';
import { RemoveMonitoredUsersRequest } from 'app/shared/models/users/requests/remove-monitored-user-request.model';
import { UserInvitation } from 'app/shared/models/users/monitored-invitation.model';

export namespace UserApiActions {
  enum UserApiActionTypes {
    ListUsers = '[Users] List users',
    ListUsersSuccess = '[Users] List users success',
    ListUsersFail = '[Users] List users fail',
    GetMonitoredUsers = '[User API] Get monitored users',
    GetMonitoredUsersSuccess = '[User API] Get monitored users success',
    GetMonitoredUsersFail = '[User API] Get monitored users fail',
    RemoveMonitoredUsers = '[User API] Remove MonitoredUsers',
    RemoveMonitoredUsersSuccess = '[User API] Remove MonitoredUsers success',
    RemoveMonitoredUsersFail = '[User API] Remove MonitoredUsers fail',
    GetUserDetails = '[Users] List user details',
    GetUserDetailsSuccess = '[Users] List user details success',
    GetUserDetailsFail = '[Users] List user details fail',
    PurgeUserDetails = '[Users] Purge user details',
    GenerateUserCode = '[Users] Get user code',
    GenerateUserCodeSuccess = '[Users] Get user code success',
    GenerateUserCodeFail = '[Users] Get user code fail',

    ChangeMonitoredUserFlow = '[Users] Change monitored user flow',
    ChangeMonitoredUserFlowSuccess = '[Users] Change monitored user flow success',
    ChangeMonitoredUserFlowFail = '[Users] Change monitored user flow fail',

    ConfirmUserAccount = '[Users] Confirm user account',
    ConfirmUserAccountSuccess = '[Users] Confirm user account success',
    ConfirmUserAccountFail = '[Users] Confirm user account fail',

    SubmitMonitoredUserInvitation = '[Users] Submit Monitored User Invitation',
    SubmitMonitoredUserInvitationSuccess = '[Users] Submit Monitored User Invitation success',
    SubmitMonitoredUserInvitationFail = '[Users] Submit Monitored User Invitation fail',

    GetMonitoredUsersInvitations = '[Users] Get monitored users invitations',
    GetMonitoredUsersInvitationsSuccess = '[Users] Get monitored users invitations success',
    GetMonitoredUsersInvitationsFail = '[Users] Get monitored users invitations fail',

    ConfirmMonitoredUserInvitation = '[Users] Confirm monitored user invitation',
    ConfirmMonitoredUserInvitationSuccess = '[Users] Confirm monitored user invitation success',
    ConfirmMonitoredUserInvitationFail = '[Users] Confirm monitored user invitation fail',

    DeclineMonitoredUserInvitation = '[Users] Decline monitored user invitation',
    DeclineMonitoredUserInvitationSuccess = '[Users] Decline monitored user invitation success',
    DeclineMonitoredUserInvitationFail = '[Users] Decline monitored user invitation fail',
  }

  // User List
  export const listUsers = createAsyncAction(UserApiActionTypes.ListUsers, {
    asyncKey: UserApiActionTypes.ListUsers,
    asyncState: 'start',
  });

  export const listUsersSuccess = createAsyncAction(
    UserApiActionTypes.ListUsersSuccess,
    {
      asyncKey: UserApiActionTypes.ListUsers,
      asyncState: 'success',
    },
    props<{ userListItem: UserListItem[] }>()
  );

  export const listUsersFail = createAsyncAction(UserApiActionTypes.ListUsersFail, {
    asyncKey: UserApiActionTypes.ListUsers,
    asyncState: 'fail',
  });

  export const getMonitoredUsers = createAsyncAction(
    UserApiActionTypes.GetMonitoredUsers,
    {
      asyncKey: UserApiActionTypes.GetMonitoredUsers,
      asyncState: 'start',
    },
    props<{ userId: string }>()
  );

  export const getMonitoredUsersSuccess = createAsyncAction(
    UserApiActionTypes.GetMonitoredUsersSuccess,
    {
      asyncKey: UserApiActionTypes.GetMonitoredUsers,
      asyncState: 'success',
    },
    props<{ users: UserListItem[] }>()
  );

  export const getMonitoredUsersFail = createAsyncAction(UserApiActionTypes.GetMonitoredUsersFail, {
    asyncKey: UserApiActionTypes.GetMonitoredUsers,
    asyncState: 'fail',
  });

  export const removeMonitoredUsers = createAsyncAction(
    UserApiActionTypes.RemoveMonitoredUsers,
    {
      asyncKey: UserApiActionTypes.RemoveMonitoredUsers,
      asyncState: 'start',
    },
    props<{ userId: string; removeMonitoredUsersRequest: RemoveMonitoredUsersRequest }>()
  );

  export const removeMonitoredUsersSuccess = createAsyncAction(
    UserApiActionTypes.RemoveMonitoredUsersSuccess,
    {
      asyncKey: UserApiActionTypes.RemoveMonitoredUsers,
      asyncState: 'success',
    },
    props<{ removedMonitoredUserIds: string[] }>()
  );

  export const removeMonitoredUsersFail = createAsyncAction(
    UserApiActionTypes.RemoveMonitoredUsersFail,
    {
      asyncKey: UserApiActionTypes.RemoveMonitoredUsers,
      asyncState: 'fail',
    }
  );

  // User Details
  export const getUserDetails = createAsyncAction(
    UserApiActionTypes.GetUserDetails,
    {
      asyncKey: UserApiActionTypes.GetUserDetails,
      asyncState: 'start',
    },
    props<{ userId: string }>()
  );

  export const getUserDetailsSuccess = createAsyncAction(
    UserApiActionTypes.GetUserDetailsSuccess,
    {
      asyncKey: UserApiActionTypes.GetUserDetailsSuccess,
      asyncState: 'success',
    },
    props<{ userDetailsResponse: UserDetailsResponse }>()
  );

  export const getUserDetailsFail = createAsyncAction(UserApiActionTypes.GetUserDetailsFail, {
    asyncKey: UserApiActionTypes.GetUserDetailsFail,
    asyncState: 'fail',
  });

  export const purgeUserDetails = createAction(UserApiActionTypes.PurgeUserDetails);

  export const generateUserCode = createAsyncAction(
    UserApiActionTypes.GenerateUserCode,
    {
      asyncKey: UserApiActionTypes.GenerateUserCode,
      asyncState: 'start',
    },
    props<{ userId: string }>()
  );

  export const generateUserCodeSuccess = createAsyncAction(
    UserApiActionTypes.GenerateUserCodeSuccess,
    {
      asyncKey: UserApiActionTypes.GenerateUserCode,
      asyncState: 'success',
    }
  );

  export const generateUserCodeFail = createAsyncAction(UserApiActionTypes.GenerateUserCodeFail, {
    asyncKey: UserApiActionTypes.GenerateUserCode,
    asyncState: 'fail',
  });

  // Change monitored user flow

  export const changeMonitoredUserFlow = createAsyncAction(
    UserApiActionTypes.ChangeMonitoredUserFlow,
    {
      asyncKey: UserApiActionTypes.ChangeMonitoredUserFlow,
      asyncState: 'start',
    },
    props<{ userId: string; changeMonitoredUserFlowRequest: ChangeMonitoredUserFlowRequest }>()
  );

  export const changeMonitoredUserFlowSuccess = createAsyncAction(
    UserApiActionTypes.ChangeMonitoredUserFlowSuccess,
    {
      asyncKey: UserApiActionTypes.ChangeMonitoredUserFlow,
      asyncState: 'success',
    },
    props<{ userId: string }>()
  );

  export const changeMonitoredUserFlowFail = createAsyncAction(
    UserApiActionTypes.ChangeMonitoredUserFlowFail,
    {
      asyncKey: UserApiActionTypes.ChangeMonitoredUserFlow,
      asyncState: 'fail',
    },
  );

  export const confirmUserAccount = createAsyncAction(
    UserApiActionTypes.ConfirmUserAccount,
    {
      asyncKey: UserApiActionTypes.ConfirmUserAccount,
      asyncState: 'start',
    },
    props<{ userId: string }>()
  );

  export const confirmUserAccountSuccess = createAsyncAction(
    UserApiActionTypes.ConfirmUserAccountSuccess,
    {
      asyncKey: UserApiActionTypes.ConfirmUserAccount,
      asyncState: 'success',
    },
    props<{ confirmed: boolean }>(),
  );

  export const confirmUserAccountFail = createAsyncAction(UserApiActionTypes.ConfirmUserAccountFail, {
    asyncKey: UserApiActionTypes.ConfirmUserAccount,
    asyncState: 'fail',
  });

  export const submitMonitoredUserInvitation = createAsyncAction(
    UserApiActionTypes.SubmitMonitoredUserInvitation,
    {
      asyncKey: UserApiActionTypes.SubmitMonitoredUserInvitation,
      asyncState: 'start',
    },
    props<{ email: string }>()
  );

  export const submitMonitoredUserInvitationSuccess = createAsyncAction(
    UserApiActionTypes.SubmitMonitoredUserInvitationSuccess,
    {
      asyncKey: UserApiActionTypes.SubmitMonitoredUserInvitation,
      asyncState: 'success',
    },
  );

  export const submitMonitoredUserInvitationFail = createAsyncAction(UserApiActionTypes.SubmitMonitoredUserInvitationFail, {
    asyncKey: UserApiActionTypes.SubmitMonitoredUserInvitation,
    asyncState: 'fail',
  });

  export const getMonitoredUsersInvitations = createAsyncAction(
    UserApiActionTypes.GetMonitoredUsersInvitations,
    {
      asyncKey: UserApiActionTypes.GetMonitoredUsersInvitations,
      asyncState: 'start',
    },
  );

  export const getMonitoredUsersInvitationsSuccess = createAsyncAction(
    UserApiActionTypes.GetMonitoredUsersInvitationsSuccess,
    {
      asyncKey: UserApiActionTypes.GetMonitoredUsersInvitations,
      asyncState: 'success',
    },
    props<{ monitoredUsersInvitations: UserInvitation[] }>()
  );

  export const getMonitoredUsersInvitationsFail = createAsyncAction(UserApiActionTypes.GetMonitoredUsersInvitationsFail, {
    asyncKey: UserApiActionTypes.GetMonitoredUsersInvitations,
    asyncState: 'fail',
  });

  export const confirmMonitoredUserInvitation = createAsyncAction(
    UserApiActionTypes.ConfirmMonitoredUserInvitation,
    {
      asyncKey: UserApiActionTypes.ConfirmMonitoredUserInvitation,
      asyncState: 'start',
    },
    props<{ monitoredUserId: string }>()
  );

  export const confirmMonitoredUserInvitationSuccess = createAsyncAction(
    UserApiActionTypes.ConfirmMonitoredUserInvitationSuccess,
    {
      asyncKey: UserApiActionTypes.ConfirmMonitoredUserInvitation,
      asyncState: 'success',
    },
    props<{ monitoredUserId: string }>()
  );

  export const confirmMonitoredUserInvitationFail = createAsyncAction(UserApiActionTypes.ConfirmMonitoredUserInvitationFail, {
    asyncKey: UserApiActionTypes.ConfirmMonitoredUserInvitation,
    asyncState: 'fail',
  });

  export const declineMonitoredUserInvitation = createAsyncAction(
    UserApiActionTypes.DeclineMonitoredUserInvitation,
    {
      asyncKey: UserApiActionTypes.DeclineMonitoredUserInvitation,
      asyncState: 'start',
    },
    props<{ monitoredUserId: string }>()
  );

  export const declineMonitoredUserInvitationSuccess = createAsyncAction(
    UserApiActionTypes.DeclineMonitoredUserInvitationSuccess,
    {
      asyncKey: UserApiActionTypes.DeclineMonitoredUserInvitation,
      asyncState: 'success',
    },
    props<{ monitoredUserId: string }>()
  );

  export const declineMonitoredUserInvitationFail = createAsyncAction(UserApiActionTypes.DeclineMonitoredUserInvitationFail, {
    asyncKey: UserApiActionTypes.DeclineMonitoredUserInvitation,
    asyncState: 'fail',
  });
}
