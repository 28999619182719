export enum GeneralHealthSubject {
  HavingPain = 'pain',
  FeelingTired = 'tired',
  FeelingSick = 'sick',
  InBadMood = 'mood',
  HavingFear = 'fear',
  FeelingDizzy = 'dizzy',
  HavingLackOfAppetite = 'appetite',
  HavingShortnessOfBreath = 'shortness-of-breath',
  FeelingBad = 'feel-good',
}

export interface GeneralHealthHistoryData {
  timestamp: string;
  subject: GeneralHealthSubject;
  value: number;
}
