import { User } from '../models';

export const getBirthday = (birthday?: string): string => {
  if (!birthday) {
    return 'N/A';
  }

  let birthDate = new Date(birthday);

  const day = birthDate.getDate();
  const month = birthDate.getMonth() + 1;
  const year = birthDate.getFullYear();
  const formattedDate = `${day.toString().padStart(2, '0')}.${month
    .toString()
    .padStart(2, '0')}.${year}`;

  return formattedDate;
};

export const getAge = (birthday?: string): number => {
  if (!birthday) {
    return 0;
  }

  let birthDate = new Date(birthday);

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const getCurrentWeight = (user: User): number => user.coreData?.weightSixMonthsAgoInKg || 0;

export const getStartWeight = (user: User): number => user.coreData?.initialWeightInKg || 0;

export const getBMI = (bodySizeInCm?: number, weight?: number): number => {
  if (!bodySizeInCm || !weight) {
    return 0;
  }

  const bodySizeInM = bodySizeInCm / 100;
  let bmi = weight / (bodySizeInM * bodySizeInM);
  bmi = Math.round(bmi * 10) / 10;
  return bmi;
};
