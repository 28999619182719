/* eslint-disable @typescript-eslint/no-namespace */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserRole } from 'app/shared/models';

import { AUTH_STATE_KEY } from '../definitions/auth.definitions';
import { AuthState } from '../reducers/auth.reducer';
import { decodeToken, getEmailFromToken } from '../utilities/auth-utilities';

export namespace AuthSelectors {
  export const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_KEY);

  export const getAuthStatus = createSelector(getAuthState, (state) => state.status);

  export const isLoggedIn = createSelector(getAuthStatus, (status) => Boolean(status.token));

  export const getSignUpEmail = createSelector(getAuthState, (state) => state.signUpEmail);
  export const getToken = createSelector(getAuthStatus, (status) => status.token);

  export const getLoggedInUser = createSelector(getAuthStatus, (status) => status.user);

  export const getUserLanguage = createSelector(
    getAuthState,
    (state) => state.status.user?.language || state.language || 'de'
  );

  export const getIsAdmin = createSelector(
    getAuthStatus,
    (state) => state.user?.role === UserRole.Admin
  );

  export const getDecodedToken = createSelector(getToken, (token) =>
    token ? decodeToken(token) : null
  );

  export const getEmailFromAuthToken = createSelector(getDecodedToken, (token) =>
    getEmailFromToken(token)
  );
}
