<!-- monitored user details -->
<div class="card px-2 py-1 mb-2 mt-1">
  <div class="row">
    <div class="col-12 col-lg-6">
      <h4 class="card-body-headline mx-6 mt-2">
        {{ 'monitoring.profile_data.last_name' | translate }}
      </h4>
      <p class="card-body-text mx-6">{{ monitoredUser.lastName }}</p>
    </div>
    <div class="col-12 col-lg-6">
      <h4 class="card-body-headline mx-6 mt-2">
        {{ 'monitoring.profile_data.first_name' | translate }}
      </h4>
      <p class="card-body-text mx-6">{{ monitoredUser.firstName }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <h4 class="card-body-headline mx-6 mt-2">
        {{ 'monitoring.profile_data.email' | translate }}
      </h4>
      <p class="card-body-text mx-6">{{ monitoredUser.email }}</p>
    </div>
    <div class="col-12 col-lg-6">
      <h4 class="card-body-headline mx-6 mt-2">{{ 'monitoring.users.age' | translate }}</h4>
      <p class="card-body-text mx-6">{{ getBirthday() }} ({{ getAge() }})</p>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="mx-6 mt-2">
        <h4 class="card-body-headline">{{ 'monitoring.users.body_size' | translate }}</h4>
        <p class="card-body-text">{{ getBodySize(monitoredUser.coreData?.bodySizeInCm) }}</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="mx-6 mt-2">
        <h4 class="card-body-headline">{{ 'monitoring.users.current_weight' | translate }}</h4>
        <p class="card-body-text">{{ getCurrentWeight() }} ({{ getCurrentBMI() }})</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="mx-6 mt-2">
        <h4 class="card-body-headline">{{ 'monitoring.users.start_weight' | translate }}</h4>
        <p class="card-body-text">{{ getStartWeight() }} ({{ getStartBMI() }})</p>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="mx-6 mt-2">
        <h4 class="card-body-headline">{{ 'monitoring.users.cancerTypes' | translate }}</h4>
        <p class="card-body-text">{{ getCancerTypes() }}</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="mx-6 mt-2">
        <h4 class="card-body-headline">{{ 'monitoring.users.cancerTherapyTypes' | translate }}</h4>
        <p class="card-body-text">{{ getCancerTherapyTypes() }}</p>
      </div>
    </div>
  </div>
</div>
