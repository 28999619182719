<div class="toast-icon"></div>
<div class="toast-content">
  <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
  </div>
  <div
    *ngIf="message && options.enableHtml"
    role="alertdialog"
    aria-live="polite"
    [class]="options.messageClass"
    [innerHTML]="message"
  ></div>
  <div
    *ngIf="message && !options.enableHtml"
    role="alertdialog"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message"
  >
    {{ message }}
  </div>
</div>
<button
  *ngIf="options.closeButton"
  (click)="remove()"
  type="button"
  class="toast-close-button"
  aria-label="Close"
>
  <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
