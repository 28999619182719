import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseAppState } from 'app/core/store/reducers';
import { tap } from 'rxjs/operators';
import { MainNavigationActions } from '../actions/main-navigation.action';

import { MAIN_NAVIGATION_PATHS } from '../definitions/main-navigation.definitions';

@Injectable()
export class MainNavigationEffects {
  public goToProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActions.goToProfile),
        tap(() => this.router.navigate([MAIN_NAVIGATION_PATHS.profile]))
      ),
    { dispatch: false }
  );

  public goToAdminArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActions.goToAdminArea),
        tap(() => this.router.navigate([MAIN_NAVIGATION_PATHS.admin]))
      ),
    { dispatch: false }
  );

  public goToUserDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActions.goToUserDetails),
        tap(({ userId }) => this.router.navigate([MAIN_NAVIGATION_PATHS.userDetails, userId]))
      ),
    { dispatch: false }
  );

  public goToLegalNotice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActions.goToLegalNotice),
        tap(() => this.router.navigate([MAIN_NAVIGATION_PATHS.legalNotice]))
      ),
    { dispatch: false }
  );

  public goToPrivacyPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainNavigationActions.goToPrivacyPolicy),
        tap(() => this.router.navigate([MAIN_NAVIGATION_PATHS.privacyPolicy]))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    public store: Store<BaseAppState>,
    private router: Router
  ) {}
}
