import { Pipe, PipeTransform } from '@angular/core';
import { SurveyElementType } from '../models/submissions/submission-list-item.model';

@Pipe({
  name: 'answerDisplay'
})
export class AnswerDisplayPipe implements PipeTransform {

  transform(value: any, type: SurveyElementType): any {
    if (type === SurveyElementType.SingleSelect) {
      return value === 0 ? 'monitored_user_submissions.no' : 'monitored_user_submissions.yes';
    } else {
      return value;
    }
  }
}
