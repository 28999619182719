import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_STATE_KEY } from '../definitions/users.definitions';
import { usersAdapter, UsersState } from '../reducers/users.reducers';

export namespace UsersSelectors {
  export const getUsersFeatureState = createFeatureSelector<UsersState>(USERS_STATE_KEY);

  export const {
    selectAll: getUsers,
    selectIds: getUserIds,
    selectEntities: getUsersMap,
  } = usersAdapter.getSelectors(getUsersFeatureState);

  export const getSelectedUserId = createSelector(
    getUsersFeatureState,
    (state) => state.selectedUserId
  );

  export const getSelectedUser = createSelector(
    getUsersMap,
    getSelectedUserId,
    (users, selectedUserId) => users && users[selectedUserId]
  );

  export const getUserDetails = createSelector(getUsersFeatureState, (state) => state.userDetails);
}
