import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'app/core/async-state/models/async-action.model';
import { handleErrors } from 'app/core/async-state/operators/handle-errors';
import * as AuthUINavigationActions from 'app/core/auth/actions/auth-ui-navigation.actions';
import { ToastService } from 'app/core/toasts/services/toast.service';
import {
  ApiResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
} from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as AuthPasswordActions from '../actions/auth-password.actions';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthPasswordEffects {
  editPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.editPassword),
        map((action) => action.payload),
        switchMap((request: UpdatePasswordRequest) =>
          this.apiService.updatePassword(request).pipe(
            handleErrors(() => AuthPasswordActions.editPasswordFail()),
            // Success action gets the whole API Response here while in other cases it just gets the data
            map((response: ApiResponse<string>) =>
              AuthPasswordActions.editPasswordSuccess({ response: response })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  // redirectAfterEditPassword$ = createEffect(() => this.actions$.pipe(
  //   ofType(AuthPasswordActions.editPasswordSuccess),
  //   map(() => )),
  //   { dispatch: true},
  // );

  showSuccessMessageAfterEditPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.editPasswordSuccess),
        tap(() => this.toastService.showInfo(this.translateService.instant('auth.password.password_changed_title'), this.translateService.instant('auth.password.password_changed_message')))
      ),
    { dispatch: false }
  );

  requestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.requestPasswordReset),
        map((action) => action.payload),
        switchMap((request: RequestPasswordResetRequest) =>
          this.apiService.requestPasswordReset(request).pipe(
            handleErrors(() => AuthPasswordActions.requestPasswordResetFail()),
            // Success action gets the whole API Response here while in other cases it just gets the data
            map((response: ApiResponse<string>) =>
              AuthPasswordActions.requestPasswordResetSuccess({ response: response })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectAfterRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.requestPasswordResetSuccess),
        map(() => AuthUINavigationActions.goToLogin())
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.requestPasswordResetSuccess),
        tap(() => this.toastService.showInfo(this.translateService.instant('auth.password.password_requested_title'), this.translateService.instant('auth.password.password_requested_message')))
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.resetPassword),
        map((action) => action.payload),
        switchMap((request: ResetPasswordRequest) =>
          this.apiService.resetPassword(request).pipe(
            handleErrors(() => AuthPasswordActions.resetPasswordFail()),
            // Success action gets the whole API Response here while in other cases it just gets the data
            map((response: ApiResponse<string>) =>
              AuthPasswordActions.resetPasswordSuccess({ response: response })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectAfterResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.resetPasswordSuccess),
        map(() => AuthUINavigationActions.goToLogin())
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthPasswordActions.resetPasswordSuccess),
        tap(() => this.toastService.showInfo(this.translateService.instant('auth.password.password_reset_title'), this.translateService.instant('auth.password.password_reset_message')))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}
}
