import { createAction, props } from '@ngrx/store';
import { ChangeMonitoredUserFlowRequest } from 'app/shared/models/users/requests/change-monitored-user-flow-request.model';
import { UserInvitation } from 'app/shared/models/users/monitored-invitation.model';

export namespace ModalsActions {
  enum ModalsActionTypes {
    OpenUserDetailsModal = '[Modals] Open user details modal',
    CloseUserDetailsModal = '[Modals] Close user details modal',
    OpenDisclaimerModal = '[Modals] Open disclaimer modal',
    CloseDisclaimerModal = '[Modals] Close disclaimer modal',
    OpenConfirmRemoveMonitoredUsersModal = '[Modal] Open Remove MonitoredUser',
    CloseConfirmRemoveMonitoredUsersModal = '[Modal] Close Remove MonitoredUser',
    OpenConfirmChangeFlowModal = '[Modal] Open Change Flow',
    CloseConfirmChangeFlowModal = '[Modal] Close Change Flow',
    OpenConsentAgreementModal = '[Modals] Open Consent Agreement Modal',
    CloseConsentAgreementModal = '[Modals] Close Consent Agreement Modal',
    OpenInviteMonitoredUsersModal = '[Modals] Open Invite Monitored User Modal',
    CloseInviteMonitoredUsersModal = '[Modals] Close Invite Monitored User Modal',
    OpenConfirmUserInvitationModal = '[Modals] Open Confirm user invitation Modal',
    CloseConfirmUserInvitationModal = '[Modals] Close Confirm user invitation Modal',
  }

  // User details
  export const openUserDetailsModal = createAction(
    ModalsActionTypes.OpenUserDetailsModal,
    props<{ userId: string }>()
  );

  export const closeUserDetailsModal = createAction(ModalsActionTypes.CloseUserDetailsModal);

  // Remove MonitoredUsers
  export const openConfirmRemoveMonitoredUsersModal = createAction(
    ModalsActionTypes.OpenConfirmRemoveMonitoredUsersModal,
    props<{ userIds: string[] }>()
  );

  export const closeConfirmRemoveMonitoredUsersModal = createAction(
    ModalsActionTypes.CloseConfirmRemoveMonitoredUsersModal
  );

  // Change MonitoredUser Flow
  export const openConfirmChangeFlowModal = createAction(
    ModalsActionTypes.OpenConfirmChangeFlowModal,
    props<{ request: ChangeMonitoredUserFlowRequest }>()
  );

  export const closeConfirmChangeFlowModal = createAction(
    ModalsActionTypes.CloseConfirmChangeFlowModal
  );

  // Disclaimer
  export const openDisclaimerModal = createAction(ModalsActionTypes.OpenDisclaimerModal);

  export const closeDisclaimerModal = createAction(ModalsActionTypes.CloseDisclaimerModal);

  // Consent Agreement
  export const openConsentAgreementModal = createAction(
    ModalsActionTypes.OpenConsentAgreementModal
  );

  export const closeConsentAgreementModal = createAction(
    ModalsActionTypes.CloseConsentAgreementModal
  );

  // Invite monitored User
  export const openInviteMonitoredUserModal = createAction(
    ModalsActionTypes.OpenInviteMonitoredUsersModal
  );

  export const closeInviteMonitoredUserModal = createAction(
    ModalsActionTypes.CloseInviteMonitoredUsersModal
  );

  // Confirm User Invitation
  export const openConfirmUserInvitationModal = createAction(
    ModalsActionTypes.OpenConfirmUserInvitationModal,
    props<UserInvitation>()
  );

  export const closeConfirmUserInvitationModal = createAction(
    ModalsActionTypes.CloseConfirmUserInvitationModal,
    props<{ id: string }>()
  );
}
