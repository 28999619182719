export enum CancerTypeTranslation {
  Unknown = 'general.cancer_type.unknown',
  Other = 'general.cancer_type.other',
  Blood = 'general.cancer_type.blood',
  Lymph = 'general.cancer_type.lymph',
  Stomach = 'general.cancer_type.stomach',
  Esphagus = 'general.cancer_type.esphagus',
  Liver = 'general.cancer_type.liver',
  Pancreas = 'general.cancer_type.pancreas',
  BileDucts = 'general.cancer_type.bileDucts',
  Bowel = 'general.cancer_type.bowel',
  Lung = 'general.cancer_type.lung',
  Breast = 'general.cancer_type.breast',
  Uterus = 'general.cancer_type.uterus',
  Ovaries = 'general.cancer_type.ovaries',
  Kidney = 'general.cancer_type.kidney',
  Bladder = 'general.cancer_type.bladder',
  Prostate = 'general.cancer_type.prostate'
}