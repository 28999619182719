import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { API_BASE_URL } from 'app/app.definitions';
import { BaseAppState } from 'app/core/store/reducers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthSelectors } from '../selectors/auth.selectors';

const REQUEST_HEADER_AUTHORIZATION = 'Authorization';

@Injectable()
export class CustomHeaderInjectorInterceptor implements HttpInterceptor {
  private readonly unauthApiUrls = [`${this.apiBaseUrl}/accounts`];

  private token: string | undefined;

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private store: Store<BaseAppState>
  ) {
    this.observeToken();
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const updatedRequestHeaders = this.getUpdatedRequestHeaders(request);
    request = request.clone({
      headers: updatedRequestHeaders,
    });

    return next.handle(request);
  }

  private getUpdatedRequestHeaders(request: HttpRequest<unknown>): HttpHeaders {
    let headers = new HttpHeaders();

    if (this.token && this.shouldAddAuthorizationHeader(request)) {
      headers = headers.append(REQUEST_HEADER_AUTHORIZATION, `Bearer ${this.token}`);
    }

    headers = headers
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .set('If-Modified-Since', '0');

    return headers;
  }

  private observeToken(): void {
    this.store
      .pipe(
        select(AuthSelectors.getToken),
        tap((token) => (this.token = token))
      )
      .subscribe();
  }

  private shouldAddAuthorizationHeader(request: HttpRequest<unknown>): boolean {
    if (!request.url) {
      return false;
    }

    if (this.unauthApiUrls.map((unauthUrl) => request.url.startsWith(unauthUrl)).includes(true)) {
      return false;
    }

    return true;
  }
}
