import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { MenuOverlayComponent } from './components/menu-overlay/menu-overlay.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LanguagePickerComponent } from 'app/views/nav-bar-ui/components/language-picker/language-picker.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InlineSVGModule } from 'ng-inline-svg-2';

export const COMPONENTS = [NavBarComponent, MenuOverlayComponent, LanguagePickerComponent];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CollapseModule,
    CommonModule,
    CustomTranslatorModule,
    BsDropdownModule,
    InlineSVGModule.forRoot(),
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class NavBarUiModule {}
