import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from 'app/core/async-state/utils/async-state-action.utils';
import { LoginRequest, LoginResponse } from 'app/shared/models';

export namespace AuthActions {
  export enum AuthActionTypes {
    Login = '[Auth] Login',
    LoginSuccess = '[Auth API] Login Success',
    LoginFail = '[Auth API] Login Failure',
    SessionExpired = '[Auth] Session expired',
    Logout = '[Auth API] Logout',
    LogoutSuccess = '[Auth API] Logout Success',
    LogoutFail = '[Auth API] Logout Fail',
    RenewCode = '[Auth API] Renew Code',
    RenewCodeSuccess = '[Auth API] Renew Code Success',
    RenewCodeFail = '[Auth API] Renew Code Failure',
    ConfirmTermsOfService = '[Auth API] Confirm Terms of Service',
    ConfirmTermsOfServiceSuccess = '[Auth API] Confirm Terms of Service Success',
    ConfirmTermsOfServiceUserFail = '[Auth API] Confirm Terms of Service Failure',
    UpdateUserLanguage = '[AuthProfile] Update user language',
    UpdateUserLanguageSuccess = '[AuthProfile] Update user language Success',
    UpdateUserLanguageFail = '[AuthProfile] Update user language Fail',
    SetUserLanguage = '[AuthProfile] Set User/Guest language',
  }

  export const login = createAsyncAction(
    AuthActionTypes.Login,
    {
      asyncKey: AuthActionTypes.Login,
      asyncState: 'start',
    },
    props<{ payload: LoginRequest }>()
  );

  export const loginSuccess = createAsyncAction(
    AuthActionTypes.LoginSuccess,
    {
      asyncKey: AuthActionTypes.Login,
      asyncState: 'success',
    },
    props<{ loginResponse: LoginResponse }>()
  );

  export const loginFail = createAsyncAction(AuthActionTypes.LoginFail, {
    asyncKey: AuthActionTypes.Login,
    asyncState: 'fail',
  });

  export const sessionExpired = createAction(AuthActionTypes.SessionExpired);

  export const logout = createAsyncAction(
    AuthActionTypes.Logout,
    {
      asyncKey: AuthActionTypes.Logout,
      asyncState: 'start',
    },
    props<{ token: string }>()
  );

  export const logoutSuccess = createAsyncAction(AuthActionTypes.LogoutSuccess, {
    asyncKey: AuthActionTypes.Logout,
    asyncState: 'success',
  });

  export const logoutFail = createAsyncAction(AuthActionTypes.LogoutFail, {
    asyncKey: AuthActionTypes.Logout,
    asyncState: 'fail',
  });

  export const renewCode = createAsyncAction(AuthActionTypes.RenewCode, {
    asyncKey: AuthActionTypes.RenewCode,
    asyncState: 'start',
  });

  export const renewCodeSuccess = createAsyncAction(
    AuthActionTypes.RenewCodeSuccess,
    {
      asyncKey: AuthActionTypes.RenewCode,
      asyncState: 'success',
    },
    props<{ code: string }>()
  );

  export const renewCodeFail = createAsyncAction(AuthActionTypes.RenewCodeFail, {
    asyncKey: AuthActionTypes.RenewCode,
    asyncState: 'fail',
  });

  export const confirmTermsOfService = createAsyncAction(AuthActionTypes.ConfirmTermsOfService, {
    asyncKey: AuthActionTypes.ConfirmTermsOfService,
    asyncState: 'start',
  });

  export const confirmTermsOfServiceSuccess = createAsyncAction(
    AuthActionTypes.ConfirmTermsOfServiceSuccess,
    {
      asyncKey: AuthActionTypes.ConfirmTermsOfService,
      asyncState: 'success',
    }
  );

  export const confirmTermsOfServiceUserFail = createAsyncAction(
    AuthActionTypes.ConfirmTermsOfServiceUserFail,
    {
      asyncKey: AuthActionTypes.ConfirmTermsOfService,
      asyncState: 'fail',
    }
  );

  export const updateUserLanguage = createAsyncAction(
    AuthActionTypes.UpdateUserLanguage,
    {
      asyncKey: AuthActionTypes.UpdateUserLanguage,
      asyncState: 'start',
    },
    props<{ language: string }>()
  );

  export const updateUserLanguageSuccess = createAsyncAction(
    AuthActionTypes.UpdateUserLanguageSuccess,
    {
      asyncKey: AuthActionTypes.UpdateUserLanguage,
      asyncState: 'success',
    },
    props<{ language: string }>()
  );

  export const updateUserLanguageFail = createAsyncAction(
    AuthActionTypes.UpdateUserLanguageFail,
    {
      asyncKey: AuthActionTypes.UpdateUserLanguage,
      asyncState: 'fail',
    }
  );

  export const setUserLanguage = createAction(
    AuthActionTypes.SetUserLanguage,
    props<{
      language: string;
    }>()
  );
}
