import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';

import { AsyncStateModule } from './async-state/async-state.module';
import { AuthModule } from './auth/auth.module';
import { ModalsModule } from './modals/modals.module';
import { NavbarModule } from './nav-bar/nav-bar.module';
import { RouterStateModule } from './router/router-state.module';
import { CoreStoreModule } from './store/core-store.module';
import { ToastModule } from './toasts/toast.module';
import { UsersModule } from './users/users.module';

@NgModule({
  imports: [
    CoreStoreModule,
    RouterStateModule,
    AsyncStateModule.forRoot(),
    AuthModule,
    ToastModule.forRoot(),
    CustomTranslatorModule,
    UsersModule,
    ModalsModule,
    NavbarModule,
  ],
  exports: [FormsModule, ReactiveFormsModule],
})
export class CoreModule {}
