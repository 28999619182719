<div class="p-2 user-detail-modal">
  <div class="d-flex w-100 justify-content-between mb-2">
    <div class="text-white default-modal-headline">
      <ng-container *ngIf="selectedUser$ | async as user">
        <span
          >{{ 'monitoring.users.monitored_user_data' | translate }} - {{ user.firstName }}
          {{ user.lastName }}</span
        >
      </ng-container>
    </div>
    <div>
      <button class="btn btn-close btn-close-white" (click)="handleCloseDialogClick()"></button>
    </div>
  </div>

  <tabset>
    <tab
      heading="{{ 'monitoring.users.monitored_user_data' | translate }}"
      [active]="selectedTab === 0"
    >
      <ng-container *ngIf="selectedUser$ | async as user">
        <app-monitored-user-details [monitoredUser]="user"></app-monitored-user-details>
      </ng-container>
    </tab>
    <tab
      heading="{{ 'monitoring.users.monitored_user_evaluation' | translate }}"
      [active]="selectedTab === 1"
    >
      <ng-container *ngIf="selectedUser$ | async as user">
        <app-monitored-user-analysis [monitoredUser]="user"></app-monitored-user-analysis>
      </ng-container>
    </tab>
    <tab
      heading="{{ 'monitoring.users.monitored_user_surveys' | translate }}"
      [active]="selectedTab === 2"
    >
      <app-monitored-user-submissions-list></app-monitored-user-submissions-list>
    </tab>
    <tab
      heading="{{ 'monitoring.users.monitored_user_flow' | translate }}"
      [active]="selectedTab === 3"
    >
      <ng-container *ngIf="selectedUser$ | async as user">
        <app-monitored-user-flow [monitoredUser]="user"></app-monitored-user-flow
      ></ng-container>
    </tab>
  </tabset>
</div>
