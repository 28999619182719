<div class="remove-modal-dialog">
  <div class="modal-content">
    <!-- head -->
    <div class="modal-header">
      <h5 class="modal-title" *ngIf="data?.length > 1">
        {{ 'modal.confirm_remove_monitored_users.title' | translate }}
      </h5>
      <h5 class="modal-title" *ngIf="data?.length == 1">
        {{ 'modal.confirm_remove_monitored_user.title' | translate }}
      </h5>
      <button type="button" class="btn-secondary btn btn-close" (click)="handleCloseDialogClick()">
        <span>&times;</span>
      </button>
    </div>

    <!-- main -->
    <div class="modal-body pt-0">
      <!-- Body -->
      <div body>
        <div class="mb-2">
          <div *ngIf="data?.length > 1; else removeMonitoredUser">
            {{ 'modal.confirm_remove_monitored_users.message_question' | translate }}
          </div>
          <ng-template #removeMonitoredUser>
            {{ 'modal.confirm_remove_monitored_user.message_question' | translate }}
          </ng-template>
        </div>
        <div>
          <div *ngIf="data?.length > 1; else removeMonitoredUserNotify">
            {{ 'modal.confirm_remove_monitored_users.message_consequences' | translate }}
          </div>
          <ng-template #removeMonitoredUserNotify>
            {{ 'modal.confirm_remove_monitored_user.message_consequences' | translate }}
          </ng-template>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="modal-footer py-4 px-2">
      <div footer class="d-flex gap-2">
        <button class="btn btn-secondary" (click)="close()">
          {{ 'modal.default_cancel_button' | translate }}
        </button>
        <button class="btn btn-danger" (click)="handleRemoveMonitoredUserClick()">
          {{ 'modal.confirm_remove_monitored_user.button_remove' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
