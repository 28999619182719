import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CancerTherapyType, CancerType, User } from 'app/shared/models';
import { TherapyTypeTranslation } from 'app/shared/models/users/cancer-therapy-type-translation.model';
import { CancerTypeTranslation } from 'app/shared/models/users/cancer-type-translation.model';
import {
  getAge,
  getBirthday,
  getBMI,
  getCurrentWeight,
  getStartWeight,
} from 'app/shared/utilities/users-utils';

@Component({
  selector: 'app-monitored-user-details',
  templateUrl: './monitored-user-details.component.html',
  styleUrls: ['./monitored-user-details.component.scss'],
})
export class MonitoredUserDetailsComponent {
  @Input() public monitoredUser: User;
  private notAvailable = 'N/A';

  public constructor(private translate: TranslateService) {}

  public getCurrentWeight(): string {
    const currentWeight = getCurrentWeight(this.monitoredUser);
    return currentWeight > 0 ? `${currentWeight} Kg` : this.notAvailable;
  }

  public getCurrentBMI(): string {
    const currentBMI = getBMI(
      this.monitoredUser.coreData?.bodySizeInCm,
      this.monitoredUser.coreData?.weightSixMonthsAgoInKg
    );

    return currentBMI > 0 ? `BMI: ${currentBMI}` : this.notAvailable;
  }

  public getStartWeight(): string {
    const startWeight = getStartWeight(this.monitoredUser);

    return startWeight > 0 ? `${startWeight} Kg` : this.notAvailable;
  }

  public getStartBMI(): string {
    const currentBMI = getBMI(
      this.monitoredUser.coreData?.bodySizeInCm,
      this.monitoredUser.coreData?.initialWeightInKg
    );

    return currentBMI > 0 ? `BMI: ${currentBMI}` : this.notAvailable;
  }

  public getBirthday(): string {
    return getBirthday(this.monitoredUser.coreData?.birthday);
  }

  public getAge(): string {
    const age = getAge(this.monitoredUser.coreData?.birthday);
    return age > 0 ? `${age}` : this.notAvailable;
  }

  public getBodySize(bodySizeInCm: number): string {
    return bodySizeInCm > 0 ? `${bodySizeInCm} cm` : this.notAvailable;
  }

  public getCancerTypes(): string {
    const cancerMap = {
      [CancerType.Other]: this.translate.instant(CancerTypeTranslation.Other),
      [CancerType.Blood]: this.translate.instant(CancerTypeTranslation.Blood),
      [CancerType.Lymph]: this.translate.instant(CancerTypeTranslation.Lymph),
      [CancerType.Stomach]: this.translate.instant(CancerTypeTranslation.Stomach),
      [CancerType.Esphagus]: this.translate.instant(CancerTypeTranslation.Esphagus),
      [CancerType.Liver]: this.translate.instant(CancerTypeTranslation.Liver),
      [CancerType.Pancreas]: this.translate.instant(CancerTypeTranslation.Pancreas),
      [CancerType.BileDucts]: this.translate.instant(CancerTypeTranslation.BileDucts),
      [CancerType.Bowel]: this.translate.instant(CancerTypeTranslation.Bowel),
      [CancerType.Lung]: this.translate.instant(CancerTypeTranslation.Lung),
      [CancerType.Breast]: this.translate.instant(CancerTypeTranslation.Breast),
      [CancerType.Uterus]: this.translate.instant(CancerTypeTranslation.Uterus),
      [CancerType.Ovaries]: this.translate.instant(CancerTypeTranslation.Ovaries),
      [CancerType.Kidney]: this.translate.instant(CancerTypeTranslation.Kidney),
      [CancerType.Bladder]: this.translate.instant(CancerTypeTranslation.Bladder),
      [CancerType.Prostate]: this.translate.instant(CancerTypeTranslation.Prostate),
    };

    return (
      this.monitoredUser?.coreData?.cancerTypes?.map((type) => cancerMap[type]).join(', ') ||
      this.notAvailable
    );
  }

  public getCancerTherapyTypes(): string {
    const therapyMap = {
      [CancerTherapyType.Unknown]: this.translate.instant(TherapyTypeTranslation.Unknown),
      [CancerTherapyType.Other]: this.translate.instant(TherapyTypeTranslation.Other),
      [CancerTherapyType.Immunobody]: this.translate.instant(TherapyTypeTranslation.Immunobody),
      [CancerTherapyType.Antibody]: this.translate.instant(TherapyTypeTranslation.Antibody),
      [CancerTherapyType.Radiation]: this.translate.instant(TherapyTypeTranslation.Radiation),
      [CancerTherapyType.Hormone]: this.translate.instant(TherapyTypeTranslation.Hormone),
      [CancerTherapyType.Chemo]: this.translate.instant(TherapyTypeTranslation.Chemo),
      [CancerTherapyType.Surgery]: this.translate.instant(TherapyTypeTranslation.Surgery),
    };

    return (
      this.monitoredUser?.coreData?.therapyTypes?.map((type) => therapyMap[type]).join(', ') ||
      this.notAvailable
    );
  }
}
