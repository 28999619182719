import { props } from '@ngrx/store';
import { createAsyncAction } from 'app/core/async-state/utils/async-state-action.utils';
import { LoginResponse } from 'app/shared/models/account/responses/login-response.model';
import { SignUpRequest } from 'app/shared/models/account/requests/sign-up-request.model';

export enum SignUpActionTypes {
  SignUp = '[SignUp] SignUp',
  SignUpSuccess = '[SignUp API] SignUp Success',
  SignUpFail = '[SignUp API] SignUp Failure',
  SignUpConfirmation = '[SignUp] SignUp Check Confirmation',
  SignUpConfirmationSuccess = '[SignUp API] SignUp Confirmation Success',
  SignUpConfirmationFail = '[SignUp API] SignUp Confirmation Failure',
  SignUpResendConfirmation = '[SignUp] SignUp Rsend Confirmation Mail',
  SignUpResendConfirmationSuccess = '[SignUp API] SignUp Rsend Confirmation Mail Success',
  SignUpResendConfirmationFail = '[SignUp API] SignUp Rsend Confirmation Mail Failure',
}

export const signUp = createAsyncAction(
  SignUpActionTypes.SignUp,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'start',
  },
  props<{ payload: SignUpRequest }>()
);

export const signUpSuccess = createAsyncAction(
  SignUpActionTypes.SignUpSuccess,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'success',
  },
  props<{ email: string }>()
);

export const signUpFail = createAsyncAction(SignUpActionTypes.SignUpFail, {
  asyncKey: SignUpActionTypes.SignUp,
  asyncState: 'fail',
});

export const signUpConfirmation = createAsyncAction(
  SignUpActionTypes.SignUpConfirmation,
  {
    asyncKey: SignUpActionTypes.SignUpConfirmation,
    asyncState: 'start',
  },
  props<{ userId: string; code: string }>()
);

export const signUpConfirmationSuccess = createAsyncAction(
  SignUpActionTypes.SignUpConfirmationSuccess,
  {
    asyncKey: SignUpActionTypes.SignUp,
    asyncState: 'success',
  },
  props<{ loginResponse: LoginResponse }>()
);

export const signUpConfirmationFail = createAsyncAction(SignUpActionTypes.SignUpConfirmationFail, {
  asyncKey: SignUpActionTypes.SignUp,
  asyncState: 'fail',
});

export const signUpResendConfirmation = createAsyncAction(
  SignUpActionTypes.SignUpResendConfirmation,
  {
    asyncKey: SignUpActionTypes.SignUpResendConfirmation,
    asyncState: 'start',
  },
  props<{ email: string }>()
);

export const signUpResendConfirmationSuccess = createAsyncAction(
  SignUpActionTypes.SignUpResendConfirmationSuccess,
  {
    asyncKey: SignUpActionTypes.SignUpResendConfirmation,
    asyncState: 'success',
  },
  props<{ loginResponse: LoginResponse }>()
);

export const signUpResendConfirmationFail = createAsyncAction(
  SignUpActionTypes.SignUpConfirmationFail,
  {
    asyncKey: SignUpActionTypes.SignUpResendConfirmation,
    asyncState: 'fail',
  }
);
