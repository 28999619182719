<div
  class="loadingindicator-scrim"
  [class.position-absolute]="isAbsolutePositioned"
  *ngIf="loading$ | async"
  @flyInOut
>
  <div class="loadingindicator-spinner-host" [class.position-absolute]="isAbsolutePositioned">
    <div class="spinner-container">
      <app-spinner [size]="size" id="loadingindicator-spinner" @flyInOutChild></app-spinner>
    </div>
  </div>
</div>
<div class="loadingindicator-prompt" *ngIf="failed$ | async" @flyInOutChild>
  <div class="card error-dialog" *ngIf="errorConfig$ | async as errorConfig">
    <div class="card-header d-flex align-items-center">
      <h4 class="float-left">{{ errorConfig.title }}</h4>
      <button
        type="button"
        class="btn-secondary btn close ms-auto"
        aria-label="Close"
        (click)="onDismissClick()"
      >
        <span aria-label="Abbrechen">&times;</span>
      </button>
    </div>
    <div class="card-body py-0">
      <p [class.m-0]="errorConfig.args?.isResendConfirmation">
        {{ errorConfig.messages }}
      </p>
      <p *ngIf="errorConfig.args?.isResendConfirmation">
        {{ 'auth.signup.no_email' | translate }}
        <span
          class="btn-link cursor-pointer"
          (click)="handleResendConfirmation($event, errorConfig.args.email)"
        >
          {{ 'auth.signup.resend' | translate }}
        </span>
      </p>
    </div>
    <div class="card-footer clearfix">
      <button
        *ngIf="errorConfig.isRetryable"
        type="button"
        id="retryButton"
        class="btn btn-primary float-right ms-2 loading-overlay-button"
        (click)="onRetryClick()"
      >
        {{ 'async_state.error_dialog_retry' | translate }}
      </button>
      <button
        *ngIf="!errorConfig.args?.isResendConfirmation"
        #dismissButton
        type="button"
        id="dismissButton"
        class="btn btn-primary float-right loading-overlay-button"
        (click)="onDismissClick()"
      >
        <span *ngIf="errorConfig.isRetryable">{{
          'async_state.error_dialog_cancel' | translate
        }}</span>
        <span *ngIf="!errorConfig.isRetryable">{{
          'async_state.error_dialog_ok' | translate
        }}</span>
      </button>
    </div>
  </div>
</div>
