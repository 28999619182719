import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'app/core/async-state/models/async-action.model';
import { handleErrors } from 'app/core/async-state/operators/handle-errors';
import { SignUpRequest } from 'app/shared/models/account/requests/sign-up-request.model';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as AuthUINavigationActions from 'app/core/auth/actions/auth-ui-navigation.actions';

import { LoginResponse } from 'app/shared/models/account/responses/login-response.model';
import * as AuthSignUpActions from '../actions/auth-sign-up.actions';
import { AuthService } from '../services/auth.service';
import { ApiResponse } from 'app/shared/models';
import { goToLogin } from 'app/core/auth/actions/auth-ui-navigation.actions';

@Injectable()
export class AuthSignUpEffects {
  signUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSignUpActions.signUp),
        map((action) => action.payload),
        exhaustMap((signUpRequest: SignUpRequest) =>
          this.authService.signUp(signUpRequest).pipe(
            handleErrors(() => AuthSignUpActions.signUpFail()),
            map(() => AuthSignUpActions.signUpSuccess({ email: signUpRequest.email })),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectToSignUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSignUpActions.signUpSuccess),
        map(() => AuthUINavigationActions.goToSignUpSuccess())
      ),
    { dispatch: true }
  );

  signUpConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSignUpActions.signUpConfirmation),
        exhaustMap((action) =>
          this.authService.confirmSignUp(action.userId, action.code).pipe(
            handleErrors(() => AuthSignUpActions.signUpConfirmationFail()),
            map((response: ApiResponse<LoginResponse>) =>
              AuthSignUpActions.signUpConfirmationSuccess({ loginResponse: response.data })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  resendConfirmationLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSignUpActions.signUpResendConfirmation),
        exhaustMap((action) =>
          this.authService.resendConfirmationLink(action.email).pipe(
            handleErrors(() => AuthSignUpActions.signUpResendConfirmationFail()),
            map(() => AuthSignUpActions.signUpResendConfirmationSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthSignUpActions.signUpConfirmation),
        map(() => goToLogin())
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
