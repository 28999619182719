import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from 'src/environments/environment';
import { localStorageSyncReducer } from './local-storage-sync.reducer';
import { logoutResetReducer } from './logout-reset.reducer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseAppState {}

export const reducers: ActionReducerMap<BaseAppState> = {};

export const metaReducers: Array<MetaReducer<BaseAppState>> = !environment.production
  ? [logoutResetReducer, storeFreeze, localStorageSyncReducer]
  : [logoutResetReducer, localStorageSyncReducer];
