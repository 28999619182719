import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'app/core/async-state/models/async-action.model';
import { handleErrors } from 'app/core/async-state/operators/handle-errors';
import { ApiResponse } from 'app/shared/models';
import { UpdateUserRequest } from 'app/shared/models/account/requests/update-user-request.model';
import { UpdateUserResponse } from 'app/shared/models/responses/update-user-response.module';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { updateUser, updateUserSuccess, updateUserFail } from '../actions/user-update-api.action';
import { UserApiService } from '../services/user-api.service';

@Injectable()
export class UserUpdateEffects {
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      switchMap((action: { payload: UpdateUserRequest; userId: string }) =>
        this.apiService.updateUser(action.userId, action.payload).pipe(
          handleErrors(() => updateUserFail()),
          map((response: ApiResponse<UpdateUserResponse>) =>
            updateUserSuccess({ response: response.data })
          ),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: UserApiService) {}
}
