import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'app/core/async-state/models/async-action.model';
import { handleErrors } from 'app/core/async-state/operators/handle-errors';
import { ApiResponse } from 'app/shared/models';
import { GetSubmissionsResponse } from 'app/shared/models/responses/get-submissions-response.modal';
import { SubmissionListItem } from 'app/shared/models/submissions/submission-list-item.model';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SubmissionApiActions } from '../../users/actions/user-submissions-api.actions';
import { UserSubmissionsApiService } from '../services/user-submissions.services';

@Injectable()
export class UserSubmissionEffects {
  public getSubmissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubmissionApiActions.getSubmissions),
        switchMap((action) =>
          this.apiService.getSubmissions(action.userId, action.monitoredUserId).pipe(
            handleErrors(() => SubmissionApiActions.getSubmissionsFail()),
            map((res: ApiResponse<GetSubmissionsResponse>) =>
              SubmissionApiActions.getSubmissionsSuccess({ payload: res.data })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  public getSubmissionDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubmissionApiActions.getSubmissionDetails),
        switchMap((action) =>
          this.apiService.getSubmissionDetails(action.userId, action.monitoredUserId, action.submissionId).pipe(
            handleErrors(() => SubmissionApiActions.getSubmissionDetailsFail()),
            map((res: ApiResponse<SubmissionListItem>) =>
              SubmissionApiActions.getSubmissionDetailsSuccess({ payload: res.data })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, private apiService: UserSubmissionsApiService) {}
}
