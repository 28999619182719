import { HttpErrorResponse } from '@angular/common/http';
import { ActionCreator, ActionCreatorProps, NotAllowedCheck } from '@ngrx/store';
import { FunctionWithParametersType } from '@ngrx/store/src/models';
import { ApiResponse } from 'app/shared/models';

import { AsyncAction, AsyncData } from '../models/async-action.model';
import { AsyncErrorState } from '../reducers/async-state.reducers';

export function createAsyncAction<T extends string, P extends object>(
  type: T,
  asyncData: AsyncData,
  config?: ActionCreatorProps<P> & NotAllowedCheck<P>
): ActionCreator<string, (props?: P & NotAllowedCheck<P>) => P & AsyncAction> {
  const as = config ? config._as : 'empty';

  switch (as) {
    case 'empty':
      return defineType(type, () => {
        return { type: type, asyncData: asyncData };
      }) as ActionCreator<string, () => P & AsyncAction>;
    case 'props':
      return defineType(type, (props) => {
        return Object.assign(Object.assign({}, props), { type: type, asyncData: asyncData });
      }) as ActionCreator<string, (props?: P & NotAllowedCheck<P>) => P & AsyncAction>;
    default:
      throw new Error('Unexpected config.');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defineType(type: string, creator: FunctionWithParametersType<any, any>) {
  return Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  });
}

export function getErrorState(asyncData: AsyncData): AsyncErrorState | undefined {
  if (
    !asyncData.asyncError &&
    !asyncData.asyncHttpError &&
    !asyncData.asyncErrorMessage &&
    !asyncData.asyncValidationErrors
  ) {
    return undefined;
  }

  const errorResponse: ApiResponse<null> =
    asyncData.asyncHttpError && asyncData.asyncHttpError.error && asyncData.asyncHttpError.error
      ? asyncData.asyncHttpError.error
      : null;

  if (asyncData.asyncHttpError && errorResponse && errorResponse.error) {
    const resError = errorResponse.error;
    const error = {
      httpStatus: asyncData.asyncHttpError.status,
      errorText: resError.message,
      validationErrors: errorResponse.validationErrors,
      args: resError.args,
    };

    return error;
  } else if (asyncData.asyncHttpError) {
    const error = {
      httpStatus: asyncData.asyncHttpError.status,
      errorTitle: asyncData.asyncErrorTitle,
      errorText: asyncData.asyncErrorMessage,
      validationErrors: asyncData.asyncValidationErrors,
      args: asyncData.args,
    };

    return error;
  } else if (asyncData.asyncError) {
    const asyncError = asyncData.asyncError as HttpErrorResponse;
    const error = {
      httpStatus: asyncError.error ? asyncError.error.error?.code : asyncError.status,
      errorText: asyncError.error && asyncError.error.error?.message,
      validationErrors: asyncError.error && asyncError.error.validationErrors,
      args: asyncError.error && asyncError.error.error?.args,
    };

    return error;
  } else {
    const error = {
      errorTitle: asyncData.asyncErrorTitle,
      errorText: asyncData.asyncErrorMessage,
      args: asyncData.args,
    };

    return error;
  }
}
