<ngx-datatable
  #datatable
  class="eimex w-100"
  [class.d-none]="data.length === 0"
  [columnMode]="config.columnMode"
  [rows]="data"
  [rowClass]="ngxConfig.rowClass"
  [headerHeight]="ngxConfig.headerHeight"
  rowHeight="auto"
  [selectionType]="config.rowsClickable ? 'single' : undefined"
  (select)="handleRowSelect($event)"
>
  <ngx-datatable-group-header *ngIf="config.groupRowsBy && config.groupHeaderTemplate">
    <ng-template
      ngx-datatable-group-header-template
      let-group="group"
      let-rowIndex="rowIndex"
      let-expanded="expanded"
    >
      <div class="cursor-pointer" (click)="handleToggleGroupExpansion(group)">
        <ng-container
          *ngTemplateOutlet="
            config.groupHeaderTemplate;
            context: {
              $implicit: group,
              group: group,
              groupBy: config.groupRowsBy,
              rowIndex: rowIndex,
              expanded: expanded
            }
          "
        ></ng-container>
      </div>
    </ng-template>
  </ngx-datatable-group-header>

  <ngx-datatable-column
    *ngFor="let column of ngxDatatableColumns; let colIndex = i"
    [class]="column.cellClass"
    [minWidth]="column.minWidth"
    [maxWidth]="column.maxWidth"
    [flexGrow]="column.flexGrow"
    [width]="column.width"
    [canAutoResize]="!column.width"
    [name]="column.name"
    [prop]="column.prop"
    [sortable]="false"
    [draggable]="false"
    [resizeable]="false"
  >
    <ng-template
      ngx-datatable-cell-template
      let-value="value"
      let-row="row"
      let-rowIndex="rowIndex"
    >
      <div
        [class.text-break-ellipsis]="column.textBreak === 'ellipsis'"
        [class.overflow-hidden]="column.textBreak === 'hidden'"
      >
        <ng-container
          *ngTemplateOutlet="
            column.cellTemplate
              ? column.cellTemplate
              : column.transformFn
              ? transformTemplate
              : valueTemplate;
            context: {
              $implicit: value,
              value: value,
              colIndex: colIndex,
              row: row,
              rowIndex: rowIndex,
              data: data,
              transform: column.transformFn
            }
          "
        ></ng-container>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template #valueTemplate let-value="value">{{ value }}</ng-template>

<ng-template
  #transformTemplate
  let-value="value"
  let-colIndex="colIndex"
  let-row="row"
  let-rowIndex="rowIndex"
  let-data="data"
  let-transform="transform"
  >{{ transform(value, colIndex, row, rowIndex, data) }}
</ng-template>
