<div id="menu-overlay" [class.visible]="isMenuOpen$ | async">
  <div class="menu-container container">
    <div class="row">
      <div class="col-xs-12">
        <ul>
          <li *ngIf="!(isAdmin$ | async)" (click)="handleUsersOverviewClick()">
            <span>{{ 'auth.monitored_users.title' | translate }}</span>
          </li>
          <li *ngIf="isAdmin$ | async" (click)="handleAdminAreaClick()">
            <span>{{ 'admin_area.admin_area' | translate }}</span>
          </li>
          <li (click)="handleProfileClick()">
            <span>{{ 'monitoring.profile_title' | translate }}</span>
          </li>
          <li (click)="handleLogoutClick()">
            <span class="text-danger">{{ 'general.logout' | translate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
