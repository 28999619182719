export function isCallWithCodeRetryable(errorCode: number): boolean {
  if (errorCode === 0) {
    // no network
    return true;
  }

  if (errorCode < 400 || errorCode > 500) {
    return false;
  }

  switch (errorCode) {
    case 401: // unauthorized
    case 403: // forbidden
    case 404: // not found
    case 400: // bad request
    case 429: // Too Many Requests: todo: handle smart
      return false;
    default:
      return true;
  }
}
