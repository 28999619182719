import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let params = {};

    // Reads all params for all child routes
    while (route.firstChild) {
      params = { ...params, ...route.params };
      route = route.firstChild;
    }
    params = { ...params, ...route.params };

    const { url } = routerState;
    let queryParams = routerState.root.queryParams;
    if (queryParams.code) {
      queryParams = {
        ...queryParams,
        code: (queryParams.code as string).split(' ').join('+'),
      };
    }

    let storeReadyUrl = url;
    if (url.indexOf('?') > -1) {
      storeReadyUrl = url.substring(0, url.indexOf('?'));
    }

    return {
      url: storeReadyUrl,
      params,
      queryParams,
    };
  }
}
