import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

const TOAST_DURATION_SHORT = 3000;
const TOAST_DURATION_LONG = 5000;

@Injectable()
export class ToastService {
  constructor(private toastrService: ToastrService) {}

  public showInfo(
    title: string,
    message?: string,
    timeOut = TOAST_DURATION_SHORT,
    tapToDismiss = true
  ): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.toastrService.info(message ? message : '', title, config);
  }

  public showSuccess(
    title: string,
    message?: string,
    timeOut = TOAST_DURATION_SHORT,
    tapToDismiss = true
  ): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.toastrService.success(message ? message : '', title, config);
  }

  public showError(
    title: string,
    message?: string,
    timeOut = TOAST_DURATION_LONG,
    tapToDismiss = true
  ): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.toastrService.error(message ? message : '', title, config);
  }

  public showWarning(
    title: string,
    message?: string,
    timeOut = TOAST_DURATION_SHORT,
    tapToDismiss = true
  ): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.toastrService.warning(message ? message : '', title, config);
  }
}
