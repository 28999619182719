import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer-ui/components/footer.component';
import { LegalNoticeComponent } from './legal-notice-ui/components/legal-notice.component';
import { PrivacyPolicyComponent } from './privacy-policy-ui/compopnents/privacy-policy.component';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';

@NgModule({
  declarations: [FooterComponent, LegalNoticeComponent, PrivacyPolicyComponent],
  imports: [CommonModule, CustomTranslatorModule],
  exports: [FooterComponent],
})
export class ViewsModule {}
