<div class="card px-2 py-1 mb-2 mt-1 flow-card">
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="mx-3 mt-2">{{ 'general.change_flow.title' | translate }}</h2>
  </div>
  <p class="mx-3 flow-headline">{{ monitoredUser.flow | flow }}</p>
  <p class="mx-3">
    {{ 'general.change_flow.description' | translate }}
    <br />
    {{ 'general.change_flow.note' | translate }}
    <br />
    {{ 'general.change_flow.email_notification' | translate }}
  </p>

  <!-- Hinzufügen der Border um den ausgewählten Flow-Text -->
  <div class="mx-3 p-3 flow-description" [ngClass]="selectedFlow">
    <p class="flow-headline">{{ 'general.change_flow.' + selectedFlow + '_flow' | translate }}</p>
    <ng-container *ngIf="selectedFlow === UserFlow.Green; else otherFlow">
      <p>{{ selectedFlowDescriptions[selectedFlow][0] | translate }}</p>
      <p>{{ selectedFlowDescriptions[selectedFlow][1] | translate }}</p>
    </ng-container>
    <ng-template #otherFlow>
      <div *ngIf="selectedFlow" ; else undefinedFlow>
        <p>{{ selectedFlowDescriptions[selectedFlow] | translate }}</p>
      </div>
      <div #undefinedFlow>
        <p>{{ 'general.flow_information.undefined_description' | translate }}</p>
      </div>
    </ng-template>
  </div>

  <div class="button-container d-flex flex-column flex-md-row mt-2 mx-3">
    <div class="red-button" (click)="updateSelectedFlow(UserFlow.Red)">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column">
          <div
            class="d-flex radio-button border-red"
            [ngClass]="{
              'checked-radio-button': selectedFlow === UserFlow.Red,
              'radio-button-red': selectedFlow === UserFlow.Red
            }"
            (mouseenter)="changeIcon(whiteFlowIcon, UserFlow.Red)"
            (mouseleave)="changeIcon('assets/images/red-flow-icon.svg', UserFlow.Red)"
          >
            <img
              class="flow-icon"
              [src]="selectedFlow === UserFlow.Red ? whiteFlowIcon : currentIcons[UserFlow.Red]"
              alt="Flow Image"
            />
            <input
              type="radio"
              name="flow"
              (change)="updateSelectedFlow(UserFlow.Red)"
              [checked]="selectedFlow === UserFlow.Red"
              class="flow-radio"
            />
            {{ 'general.change_flow.red_flow' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="yellow-button" (click)="updateSelectedFlow(UserFlow.Yellow)">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column">
          <div
            class="d-flex radio-button border-yellow"
            [ngClass]="{
              'checked-radio-button': selectedFlow === UserFlow.Yellow,
              'radio-button-yellow': selectedFlow === UserFlow.Yellow
            }"
            (mouseenter)="changeIcon(whiteFlowIcon, UserFlow.Yellow)"
            (mouseleave)="changeIcon('assets/images/yellow-flow-icon.svg', UserFlow.Yellow)"
          >
            <img
              class="flow-icon"
              [src]="
                selectedFlow === UserFlow.Yellow ? whiteFlowIcon : currentIcons[UserFlow.Yellow]
              "
              alt="Flow Image"
            />
            <input
              type="radio"
              name="flow"
              (change)="updateSelectedFlow(UserFlow.Yellow)"
              [checked]="selectedFlow === UserFlow.Yellow"
              class="flow-radio"
            />
            {{ 'general.change_flow.yellow_flow' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="monitoredUser.flow !== UserFlow.Red"
      class="green-button"
      (click)="updateSelectedFlow(UserFlow.Green)"
    >
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column">
          <div
            class="d-flex radio-button border-green"
            [ngClass]="{
              'checked-radio-button': selectedFlow === UserFlow.Green,
              'radio-button-green': selectedFlow === UserFlow.Green
            }"
            (mouseenter)="changeIcon(whiteFlowIcon, UserFlow.Green)"
            (mouseleave)="changeIcon('assets/images/green-flow-icon.svg', UserFlow.Green)"
          >
            <img
              class="flow-icon"
              [src]="selectedFlow === UserFlow.Green ? whiteFlowIcon : currentIcons[UserFlow.Green]"
              alt="Flow Image"
            />
            <input
              type="radio"
              name="flow"
              (change)="updateSelectedFlow(UserFlow.Green)"
              [checked]="selectedFlow === UserFlow.Green"
              class="flow-radio"
            />
            {{ 'general.change_flow.green_flow' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="flow-btn-container">
      <button
        type="button"
        class="btn btn-primary flow-btn"
        (click)="handleChangeFlow(monitoredUser.id)"
      >
        {{ 'general.change_flow.button' | translate }}
      </button>
    </div>
  </div>
</div>
