import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BaseAppState } from 'app/core/store/reducers';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AUTH_UI_PATHS } from '../definitions/auth-ui-navigation.definitions';
import { AuthSelectors } from '../selectors/auth.selectors';

@Injectable()
export class LoggedInGuard implements CanActivate {
  isLoggedIn$ = this.store.pipe(select(AuthSelectors.isLoggedIn));

  constructor(private router: Router, private store: Store<BaseAppState>) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn$.pipe(
      take(1),
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        }
        const redirectUrlTree = this.router.createUrlTree([AUTH_UI_PATHS.root.login]);
        return redirectUrlTree;
      })
    );
  }
}
