import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserApiActions } from 'app/core/users/actions/user-api.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(take(1)).subscribe((loggedIn) => {
      if (!loggedIn) {
        this.setInitialLanguage();
      }
    });
    this.store.dispatch(UserApiActions.getMonitoredUsersInvitations());
  }

  private setInitialLanguage() {
    const browserLang = navigator.language.split('-')[0];
    const defaultLang = 'en';
    const supportedLangs = this.translate.getLangs();

    const langToUse = supportedLangs.includes(browserLang) ? browserLang : defaultLang;
    this.translate.setDefaultLang(langToUse);
    this.translate.use(langToUse);
  }
}
